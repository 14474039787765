import React, { useState } from "react";
import axios from "axios";
import MyButton from "../../CommonFolder/MyButton";
import InputFiled from "../../CommonFolder/FormStructure/InputField";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [error, setError] = useState(null); // To display any error messages
  const navigate = useNavigate();

  const email = localStorage.getItem("email");
  const otp = localStorage.getItem("otp");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!email || !otp) {
      setError("Email or OTP not found. Please go back and verify.");
      return;
    }

    if (password !== cPassword) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/users/reset-password",
        {
          email,
          otp: Number(otp), // Convert OTP to a number
          password,
          c_password: cPassword,
        }
      );

      if (response.status === 200) {
        navigate("/login");
        localStorage.removeItem("email");
        localStorage.removeItem("otp");
      }
    } catch (error) {
      setError("Failed to change password. Please try again.");
      console.error("API error:", error);
    }
  };

  return (
    <>
      <form className="row g-3 w-100" onSubmit={handleSubmit}>
        <div className="FormBox">
          <InputFiled
            InputBoxClassname="col-md-12"
            InputType="password"
            Inputplaceholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <InputFiled
            InputBoxClassname="col-md-12"
            InputType="password"
            Inputplaceholder="Re-enter Password"
            value={cPassword}
            onChange={(e) => setCPassword(e.target.value)}
          />

          {error && <p className="text-danger">{error}</p>}

          <MyButton
            title="Change Password"
            className="w-100 MyBtn"
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
