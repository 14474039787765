import React, { useState } from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import MyButton from "../CommonFolder/MyButton";
import Modal from "../Deals/Modal";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import validationRules from "../CommonFolder/FormStructure/validationRules.json";

const AddDeal = ({ setIsLogin }) => {
  let half = "mb-3 DisabledInputs";
  const usertoken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    streetAddress: "",
    city: 0,
    state: 0,
    zipCode: "",
    vehicle: "",
    vinNumber: "",
    vehicleColor: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    let parsedValue = value;

    // Parse the value for city and state as numbers
    if (name === "city" || name === "state") {
      parsedValue = parseInt(value, 10);
    } else if (
      name === "phoneNumber" ||
      name === "vinNumber" ||
      name === "zipCode"
    ) {
      parsedValue = value.replace(/\D/g, ""); // Keep digits only for certain fields
    }

    setFormData({ ...formData, [name]: parsedValue });
  };

  const validateForm = () => {
    const newErrors = {};

    for (const [field, rules] of Object.entries(validationRules)) {
      const value = formData[field];

      if (rules.required && !value) {
        newErrors[field] = rules.message;
      } else if (
        rules.minLength &&
        value.replace(/\D/g, "").length < rules.minLength
      ) {
        newErrors[field] = rules.message;
      } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
        newErrors[field] = rules.message;
      } else if (
        rules.length &&
        value.replace(/\D/g, "").length !== rules.length
      ) {
        newErrors[field] = rules.message;
      }
    }

    // Check if select fields are selected
    if (!formData.city) newErrors.city = "Please select a city.";
    if (!formData.state) newErrors.state = "Please select a state.";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    fetch("https://api.menuverse.ai/newdeal/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            localStorage.clear();
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const dealId = data.data.id;
        toast.dismiss();
        toast.success("Your deal has been added successfully!");
        navigate(`/DetailVerification/${dealId}`);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Failed to add the deal.");
      });
  };

  return (
    <section className="Body_Section">
      <div className="container-fluid px-lg-20">
        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <div className="Full_Table">
              <TableHeader title="ABC Dealer Group" showSareLink={true} />
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                id="Add-Deal-Form"
                noValidate
              >
                <div className="container Add_Dealer">
                  <div className="row row-cols-2">
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="firstName"
                        lableValue="First Name"
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                        errorsMSG={errors.firstName}
                      />
                      {errors.firstName && (
                        <p className="Add-Deal-Error-msg">{errors.firstName}</p>
                      )}
                    </div>
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="middleName"
                        lableValue="Middle Name"
                        required
                        value={formData.middleName}
                        onChange={handleChange}
                        errorsMSG={errors.middleName}
                      />
                      {errors.middleName && (
                        <p className="Add-Deal-Error-msg">
                          {errors.middleName}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="lastName"
                        lableValue="Last Name"
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                        errorsMSG={errors.lastName}
                      />
                      {errors.lastName && (
                        <p className="Add-Deal-Error-msg">{errors.lastName}</p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="tel"
                        InputName="phoneNumber"
                        lableValue="Phone number"
                        required
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        InputID={"phoneNumber"}
                        errorsMSG={errors.phoneNumber}
                        maxlength="10"
                      />
                      {errors.phoneNumber && (
                        <p className="Add-Deal-Error-msg">
                          {errors.phoneNumber}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="email"
                        InputName="email"
                        lableValue="Email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                        InputID={"email"}
                        errorsMSG={errors.email}
                      />
                      {errors.email && (
                        <p className="Add-Deal-Error-msg">{errors.email}</p>
                      )}
                    </div>
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="streetAddress"
                        lableValue="Street Address"
                        required
                        value={formData.streetAddress}
                        onChange={handleChange}
                        errorsMSG={errors.streetAddress}
                      />
                      {errors.streetAddress && (
                        <p className="Add-Deal-Error-msg">
                          {errors.streetAddress}
                        </p>
                      )}
                    </div>

                    <SelectField
                      required
                      className={half}
                      InputName="city"
                      Selectlabel="City"
                      value={formData.city}
                      onChange={handleChange}
                      options={[
                        { value: 1, label: "New York City" },
                        { value: 2, label: "Los Angeles" },
                        { value: 3, label: "Chicago" },
                        { value: 4, label: "Houston" },
                        { value: 5, label: "Phoenix" },
                        { value: 6, label: "Philadelphia" },
                        { value: 7, label: "San Antonio" },
                        { value: 8, label: "San Diego" },
                        { value: 9, label: "Dallas" },
                        { value: 10, label: "San Jose" },
                        { value: 11, label: "Austin" },
                        { value: 12, label: "Jacksonville" },
                        { value: 13, label: "San Francisco" },
                        { value: 14, label: "Indianapolis" },
                        { value: 15, label: "Seattle" },
                        { value: 16, label: "Denver" },
                        { value: 17, label: "Washington D.C." },
                        { value: 18, label: "Boston" },
                        { value: 19, label: "El Paso" },
                        { value: 20, label: "Detroit" },
                        { value: 21, label: "Nashville" },
                        { value: 22, label: "Memphis" },
                        { value: 23, label: "Portland" },
                        { value: 24, label: "Las Vegas" },
                        { value: 25, label: "Louisville" },
                        { value: 26, label: "Milwaukee" },
                        { value: 27, label: "Baltimore" },
                        { value: 28, label: "Albany" },
                        { value: 29, label: "Columbus" },
                        { value: 30, label: "Cincinnati" },
                        { value: 31, label: "St. Louis" },
                        { value: 32, label: "Kansas City" },
                        { value: 33, label: "Charlotte" },
                        { value: 34, label: "Tampa" },
                        { value: 35, label: "Pittsburgh" },
                        { value: 36, label: "Orlando" },
                        { value: 37, label: "St. Paul" },
                      ]}
                    />

                    <SelectField
                      required
                      className={half}
                      InputName="state"
                      Selectlabel="State"
                      value={formData.state}
                      onChange={handleChange}
                      options={[
                        { value: 1, label: "Alabama" },
                        { value: 2, label: "Alaska" },
                        { value: 3, label: "Arizona" },
                        { value: 4, label: "Arkansas" },
                        { value: 5, label: "California" },
                        { value: 6, label: "Colorado" },
                        { value: 7, label: "Connecticut" },
                        { value: 8, label: "Delaware" },
                        { value: 9, label: "Florida" },
                        { value: 10, label: "Georgia" },
                        { value: 11, label: "Hawaii" },
                        { value: 12, label: "Idaho" },
                        { value: 13, label: "Illinois" },
                        { value: 14, label: "Indiana" },
                        { value: 15, label: "Iowa" },
                        { value: 16, label: "Kansas" },
                        { value: 17, label: "Kentucky" },
                        { value: 18, label: "Louisiana" },
                        { value: 19, label: "Maine" },
                        { value: 20, label: "Maryland" },
                        { value: 21, label: "Massachusetts" },
                        { value: 22, label: "Michigan" },
                        { value: 23, label: "Minnesota" },
                        { value: 24, label: "Mississippi" },
                        { value: 25, label: "Missouri" },
                        { value: 26, label: "Montana" },
                        { value: 27, label: "Nebraska" },
                        { value: 28, label: "Nevada" },
                        { value: 29, label: "New Hampshire" },
                        { value: 30, label: "New Jersey" },
                        { value: 31, label: "New Mexico" },
                        { value: 32, label: "New York" },
                        { value: 33, label: "North Carolina" },
                        { value: 34, label: "North Dakota" },
                        { value: 35, label: "Ohio" },
                        { value: 36, label: "Oklahoma" },
                        { value: 37, label: "Oregon" },
                        { value: 38, label: "Pennsylvania" },
                        { value: 39, label: "Rhode Island" },
                        { value: 40, label: "South Carolina" },
                        { value: 41, label: "South Dakota" },
                        { value: 42, label: "Tennessee" },
                        { value: 43, label: "Texas" },
                        { value: 44, label: "Utah" },
                        { value: 45, label: "Vermont" },
                        { value: 46, label: "Virginia" },
                        { value: 47, label: "Washington" },
                        { value: 48, label: "West Virginia" },
                        { value: 49, label: "Wisconsin" },
                        { value: 50, label: "Wyoming" },
                      ]}
                    />

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="zipCode"
                        lableValue="Zip code"
                        required
                        value={formData.zipCode}
                        onChange={handleChange}
                        InputID={"zipCode"}
                        errorsMSG={errors.zipCode}
                        maxlength="5"
                      />
                      {errors.zipCode && (
                        <p className="Add-Deal-Error-msg">{errors.zipCode}</p>
                      )}
                    </div>
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="vehicle"
                        lableValue="Vehicle"
                        value={formData.vehicle}
                        onChange={handleChange}
                        errorsMSG={errors.vehicle}
                      />
                      {errors.vehicle && (
                        <p className="Add-Deal-Error-msg">{errors.vehicle}</p>
                      )}
                    </div>
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="tel"
                        InputName="vinNumber"
                        lableValue="VIN number"
                        value={formData.vinNumber}
                        onChange={handleChange}
                        errorsMSG={errors.vinNumber}
                        maxlength="10"
                      />
                      {errors.vinNumber && (
                        <p className="Add-Deal-Error-msg">{errors.vinNumber}</p>
                      )}
                    </div>
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="vehicleColor"
                        lableValue="Vehicle color"
                        value={formData.vehicleColor}
                        onChange={handleChange}
                      />
                      {errors.vehicleColor && (
                        <p className="Add-Deal-Error-msg">
                          {errors.vehicleColor}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="Table_foot justify-content-end">
                  <div className="Foot_btn">
                    <MyButton type="submit" title="Create deal" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </section>
  );
};

export default AddDeal;
