import React, {
  useState,
  useEffect,
  Suspense,
  useCallback,
  useContext,
  useRef,
} from "react";
import "./Navbar.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  RxEnterFullScreen,
  RxExitFullScreen,
  RxHamburgerMenu,
} from "react-icons/rx";
import { IoSettingsSharp } from "react-icons/io5";
import { FaClock } from "react-icons/fa";
import { AudioContext } from "../../App";
import "bootstrap/dist/css/bootstrap.min.css";
import Peer from "peerjs";
import { Modal, Button } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import { io } from "socket.io-client";
import useSocket from "./useSocket";

const NavbarModal = React.lazy(() => import("../NavBar/NavbarModal"));

const Navbar = ({ onLogout, handle }) => {
  const [showClock, setShowClock] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [userName, setUserName] = useState("");
  const [fullScreen, setfullScreen] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [countdownActive, setCountdownActive] = useState(false);
  const [IsShared, setIsShared] = useState(false);

  const playclick = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };
  const playAudio = () => {
    playclick("/assets/Audios/clicksoundeffect.mp3");
  };

  const [peerId, setPeerId] = useState("");
  const [remotePeerId, setRemotePeerId] = useState("");
  const [peer, setPeer] = useState(null);
  const [call, setCall] = useState(null);
  const [stream, setStream] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [isCallPending, setIsCallPending] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const audioRef = useRef(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);

  const [customerId, setCustomerId] = useState(null);
  const [customerUrls, setCustomerUrls] = useState([]);
  const [singleCustomerUrl, setSingleCustomerUrl] = useState(null);
  const [isEventEmitted, setIsEventEmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const socket = io("http://localhost:3000");

  ///////////////////////////  store crediential in localhost by admin /////////////////////////////

  useEffect(() => {
    const [baseUrl, queryString] = window.location.href.split("?");

    if (queryString && queryString.includes("userId=")) {
      const params = new URLSearchParams("?" + queryString);
      const id = params.get("userId");
      if (id) {
        setCustomerId(id);
        localStorage.setItem("userId", id);
        localStorage.setItem("controller", "admin");
        localStorage.setItem(
          "token",
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImtzQG1haWxpbmF0b3IuY29tIiwiaWF0IjoxNzI1NTM3MTAzLCJleHAiOjE3MjU2MjM1MDN9.QBQ6R7i_uv_RWLhhaO7sgS0ZnjiytHkjZQs5Z9U6758"
        );
        console.log(`Customer ID: ${id}`);
      }
    }
  }, []);

  ////////////////////////////// store url and redirect ////////////////////////////////
  const isAdmin = localStorage.getItem("controller");

  useEffect(() => {
    const storeCustomerUrl = async () => {
      const customerId = localStorage.getItem("userId");
      const isAdmin = localStorage.getItem("controller");
      const url = window.location.href.split("?")[0];

      if (customerId && isAdmin !== "admin") {
        try {
          const response = await axios.post(
            "http://localhost:3000/api/customers",
            {
              customerId,
              url,
            }
          );
          console.log("Response:", response.data);
        } catch (error) {
          console.error("Error storing customer URL:", error);
        }
      } else {
        console.log(
          "API call skipped. User is admin or customer ID not found."
        );
      }
    };

    storeCustomerUrl();
  }, []);

  useEffect(() => {
    socket.on("singleCustomerUrl", (url) => {
      console.log("Received single customer URL:", url);
      setSingleCustomerUrl(url);

      if (isAdmin === "admin") {
        const newUrl = url.url.split("https://api.menuverse.ai")[1];
        navigate(newUrl);
      }
    });

    return () => {
      socket.off("singleCustomerUrl");
    };
  }, []);

  ///////////////////////// redirection control /////////////////////

  const customer_Id = localStorage.getItem("userId");

  useEffect(() => {
    if (customer_Id && !isEventEmitted) {
      socket.emit("getSingleCustomerUrl", customer_Id); // Emit event to request customer URL
      setIsEventEmitted(true); // Set flag to true after emitting
    }

    const handleSingleCustomerUrl = (url) => {
      console.log("Received:", url);
      setSingleCustomerUrl(url);

      if (isAdmin === "admin" && url.url) {
        const newUrl = url.url.split("https://api.menuverse.ai")[1]; // Extract path after localhost:3002
        navigate(newUrl); // Navigate to the new URL
      }
    };

    socket.on("singleCustomerUrl", handleSingleCustomerUrl);

    return () => {
      socket.off("singleCustomerUrl", handleSingleCustomerUrl); // Cleanup listener
    };
  }, [customer_Id, isEventEmitted, isAdmin, navigate, socket]);

  ////////////////////////////////////// video call /////////////////////////////////
  useEffect(() => {
    const newPeer = new Peer();

    newPeer.on("open", (id) => {
      setPeerId(id);
    });

    newPeer.on("call", (incoming) => {
      setIncomingCall(incoming);
      setIsCallPending(true);
      setShowModal(true);
      if (audioRef.current) {
        audioRef.current.play();
      }

      incoming.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
      });
    });

    setPeer(newPeer);

    return () => {
      newPeer.destroy();
    };
  }, []);

  useEffect(() => {
    if (peer) {
      peer.on("data", (data) => {
        const event = JSON.parse(data);

        if (event.type === "mousemove") {
          const mouseMoveEvent = new MouseEvent("mousemove", {
            clientX: event.x,
            clientY: event.y,
          });
          document
            .elementFromPoint(event.x, event.y)
            .dispatchEvent(mouseMoveEvent);
        } else if (event.type === "click") {
          const clickEvent = new MouseEvent("click", {
            clientX: event.x,
            clientY: event.y,
          });
          document.elementFromPoint(event.x, event.y).dispatchEvent(clickEvent);
        } else if (event.type === "keydown") {
          const keyDownEvent = new KeyboardEvent("keydown", {
            key: event.key,
          });
          document.dispatchEvent(keyDownEvent);
        }
      });
    }
  }, [peer]);

  useEffect(() => {
    let intervalId;

    if (showClock) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [showClock]);

  useEffect(() => {
    const storedCountdown = localStorage.getItem("countdown");
    if (storedCountdown) {
      const remainingTime = parseInt(storedCountdown, 10);
      setCountdown(remainingTime);
      setCountdownActive(true);
    } else {
      const storedCountdownValue = localStorage.getItem(
        "selectedValueForWaiting"
      );
      if (storedCountdownValue) {
        const countdownValue = parseInt(storedCountdownValue, 10) * 60; // convert minutes to seconds
        setCountdown(countdownValue);
        setCountdownActive(true);
      }
    }
  }, []);

  useEffect(() => {
    let countdownInterval;

    if (countdownActive && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          localStorage.setItem("countdown", newCountdown); // Save the remaining time to local storage
          if (newCountdown <= 0) {
            clearInterval(countdownInterval);
            return 0;
          }
          return newCountdown;
        });
      }, 1000);
    } else if (countdown <= 0) {
      localStorage.removeItem("countdown");
    }

    return () => clearInterval(countdownInterval);
  }, [countdownActive, countdown]);

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName || userName) {
      const decodedUserName = decodeURIComponent(storedUserName);
      setUserName(decodedUserName);
    }
  }, []);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleLogout = () => {
    localStorage.clear();
    onLogout();
  };

  const isAddDealPageArr = ["/AddDeal", "/DetailVerification"];
  const isAddDealPage = isAddDealPageArr.includes(window.location.pathname);

  const toggleFullScreen = () => {
    if (!fullScreen) {
      handle.enter();
    } else {
      handle.exit();
    }
    setfullScreen(!fullScreen);
  };

  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  useEffect(() => {
    const shared = localStorage.getItem("isShared") === "true";
    setIsShared(shared);
  }, []);
  const DealId = localStorage.getItem("DealId");
  const destinationUrl = !IsShared ? "/" : `/DetailVerification/${DealId}`;

  const handleClickauio = () => {
    playAudio();
  };

  const startCall = () => {
    if (!remotePeerId || !peer) return;

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        localVideoRef.current.srcObject = mediaStream;

        const outgoingCall = peer.call(remotePeerId, mediaStream);

        outgoingCall.on("stream", (remoteStream) => {
          remoteVideoRef.current.srcObject = remoteStream;
        });

        outgoingCall.on("close", () => {
          endCall();
        });

        setCall(outgoingCall);
      });
  };

  const acceptCall = () => {
    if (!incomingCall || !peer) return;

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        localVideoRef.current.srcObject = mediaStream;
        incomingCall.answer(mediaStream);

        incomingCall.on("stream", (remoteStream) => {
          remoteVideoRef.current.srcObject = remoteStream;
        });

        incomingCall.on("close", () => {
          endCall();
        });

        setCall(incomingCall);
        setIncomingCall(null);
        setIsCallPending(false);

        // Stop the incoming call sound
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0; // Reset the playback time
        }
      });
  };

  const declineCall = () => {
    if (incomingCall) {
      incomingCall.close();
      setIncomingCall(null);
      setIsCallPending(false);

      // Stop the incoming call sound
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the playback time
      }
    }
  };

  const endCall = () => {
    if (call) {
      call.close();
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setCall(null);
    setStream(null);
    localVideoRef.current.srcObject = null;
    remoteVideoRef.current.srcObject = null;
    setShowModal(false); // Close the modal when the call ends
  };

  const toggleVideo = () => {
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoMuted(!videoTrack.enabled);
      }
    }
  };

  const toggleAudio = () => {
    if (stream) {
      const audioTrack = stream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsAudioMuted(!audioTrack.enabled);
      }
    }
  };

  const startScreenShare = async () => {
    if (!peer || !call) return;

    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      const videoTrack = screenStream.getVideoTracks()[0];
      const sender = call.peerConnection
        .getSenders()
        .find((s) => s.track.kind === "video");

      if (sender) {
        sender.replaceTrack(videoTrack);
      }

      localVideoRef.current.srcObject = screenStream;
      setIsScreenSharing(true);

      videoTrack.onended = () => {
        stopScreenShare();
      };
    } catch (error) {
      console.error("Error during screen sharing:", error);
    }
  };

  const stopScreenShare = async () => {
    if (!peer || !call || !isScreenSharing) return;

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      const videoTrack = mediaStream.getVideoTracks()[0];
      const sender = call.peerConnection
        .getSenders()
        .find((s) => s.track.kind === "video");

      if (sender) {
        sender.replaceTrack(videoTrack);
      }

      localVideoRef.current.srcObject = mediaStream;
      setIsScreenSharing(false);
    } catch (error) {
      console.error("Error while stopping screen sharing:", error);
    }
  };

  // const [customerUrls, setCustomerUrls] = useState([]);
  // const [singleCustomerUrl, setSingleCustomerUrl] = useState(null); // New state for single customer URL

  // useEffect(() => {
  //   const socket = io('http://localhost:3000'); // Replace with your server URL

  //   // Emit event to request customer URLs
  //   socket.emit('getCustomerUrls');

  //   // Listen for customer URLs response
  //   socket.on('customerUrls', (urls) => {
  //     setCustomerUrls(urls);
  //     console.log("Received customer URLs:", urls);
  //   });

  //   // Listen for error messages
  //   socket.on('error', (message) => {
  //     console.error(message);
  //   });

  //   // Retrieve customerId from local storage
  //   const customerId = localStorage.getItem('userId'); // Adjust the key as necessary
  //   const isAdmin = localStorage.getItem('controller');
  //   // Emit event to get the single customer URL
  //   if (customerId) {
  //     socket.emit('getSingleCustomerUrl', customerId);
  //   }

  //   // Listen for single customer URL response
  //   socket.on('singleCustomerUrl', (url) => {
  //     console.log("Received single customer URL:", url);
  //     setSingleCustomerUrl(url);

  //     if (isAdmin === 'admin') {
  //       // Navigate to the URL (removing the 'https://api.menuverse.ai/' part)
  //       const newUrl = url.url.split('https://api.menuverse.ai')[1]; // Get the path after localhost:3002
  //       navigate(newUrl); // Use the navigate function to go to the new URL
  //     }
  //   });

  //   // Clean up on component unmount
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [navigate]);

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md py-0 w-100">
          <div className="container-fluid px-lg-20">
            <div className="left_column">
              <NavLink to={destinationUrl} aria-label="Read">
                <img
                  src="/Images/Group34011.png"
                  alt=""
                  onClick={() => play("/assets/Audios/clicksoundeffect.mp3")}
                />
              </NavLink>
              {/* <ul>
        {customerUrls.map((customer) => (
          <li className="text-dark" key={customer.customerId}>
            ID: {customer.customerId}, URL: <a href={customer.url} target="_blank" rel="noopener noreferrer">{customer.url}</a>
          </li>
        ))}
        </ul> */}
              <img src="/Images/Frame12.png" alt="" />
            </div>
            <div className="text-end">
              <button
                className="d-md-none border-0 px-2 py-1 RxHamburgerMenu"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasResponsive"
                aria-controls="offcanvasResponsive"
              >
                <RxHamburgerMenu />
              </button>
            </div>

            <audio ref={audioRef} className="d-none">
              <source src="/Images/skype-incoming.mp3" type="audio/mpeg" />
            </audio>

            <div
              className="offcanvas-md offcanvas-end"
              id="offcanvasResponsive"
              aria-labelledby="offcanvasResponsiveLabel"
            >
              <div className="offcanvas-header justify-content-end ">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#offcanvasResponsive"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div className="Right_column Mobile-Sidebar-ele">
                  <div className="Mobile-profile">
                    <img
                      src="/Images/Group18.png"
                      alt=""
                      className="Profile_img"
                    />
                    <span>{userName}</span>
                  </div>

                  <button
                    className="FullScreen_Button"
                    onClick={toggleFullScreen}
                    aria-label="Fullscreen"
                  >
                    {fullScreen ? (
                      <RxExitFullScreen
                        onClick={() =>
                          play("/assets/Audios/clicksoundeffect.mp3")
                        }
                      />
                    ) : (
                      <RxEnterFullScreen
                        onClick={() =>
                          play("/assets/Audios/clicksoundeffect.mp3")
                        }
                      />
                    )}
                    <span className="Mobile-sidebar-option">Fullscreen</span>
                  </button>

                  <img
                    src="/Images/call.png"
                    onClick={() => setShowModal(true)}
                    alt=""
                  />

                  <div
                    className="Clock_box"
                    style={{ display: isAddDealPage ? "none" : "block" }}
                  >
                    <div className="d-flex align-items-center CLock-inner">
                      {/* <img src="/Images/Clock.png" alt="" /> */}
                      <FaClock />

                      <span>
                        {formatTime(countdown > 0 ? countdown : elapsedTime)}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center Lanuage-Inner">
                    <label className="form-check-label">ENG</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onClick={() =>
                          play("/assets/Audios/clicksoundeffect.mp3")
                        }
                        aria-label="Search"
                      />
                    </div>
                    <label className="form-check-label">ESP</label>
                  </div>
                  <div className="Line">|</div>

                  <a
                    href="/"
                    className="Setting_Link"
                    data-bs-toggle="modal"
                    data-bs-target="#SettingsModal"
                    aria-label="Read"
                  >
                    <IoSettingsSharp
                      onClick={() =>
                        play("/assets/Audios/clicksoundeffect.mp3")
                      }
                      style={{ color: "black" }}
                    />

                    <span className="Mobile-sidebar-option">Settings</span>
                  </a>
                  <div className="Mobile-logout">
                    <NavLink
                      className="dropdown-item logout"
                      onClick={handleLogout}
                    >
                      <img
                        src="/assets/Images/NavImage/sign-out-alt.png"
                        alt=""
                        className=""
                      />
                      <span>Logout</span>
                    </NavLink>
                  </div>

                  <div
                    className="dropdown Logout_dropdown"
                    onClick={() => play("/assets/Audios/clicksoundeffect.mp3")}
                  >
                    {!IsShared ? (
                      <a
                        herf="#"
                        className="Profile_Link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="/Images/Group18.png"
                          alt=""
                          className="Profile_img"
                        />
                        <div>
                          <p>Your F&I </p>
                          <span>{userName}</span>
                        </div>
                      </a>
                    ) : (
                      <div className="Profile_Link-IsShared">
                        <img
                          src="/Images/Group18.png"
                          alt=""
                          className="Profile_img"
                        />
                        <div className="Profile_Link-data">
                          <p>Your F&I </p>
                          <span>{userName}</span>
                        </div>
                      </div>
                    )}

                    <ul className="dropdown-menu">
                      <li>
                        <NavLink
                          className="dropdown-item logout"
                          onClick={handleLogout}
                        >
                          <img
                            src="/assets/Images/NavImage/sign-out-alt.png"
                            alt=""
                            className=""
                          />
                          <span>Logout</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <Suspense>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Video Call</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="video-call-container">
              <div className="video-wrapper">
                <video
                  ref={localVideoRef}
                  autoPlay
                  playsInline
                  muted
                  className="local-video"
                />
                <video
                  ref={remoteVideoRef}
                  autoPlay
                  playsInline
                  className="remote-video"
                />
              </div>

              {isCallPending && incomingCall && (
                <div className="call-prompt">
                  <h3>Incoming Call from {incomingCall.peer}</h3>
                  <Button
                    onClick={acceptCall}
                    className="accept-button"
                    variant="success"
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={declineCall}
                    className="decline-button"
                    variant="danger"
                  >
                    Decline
                  </Button>
                </div>
              )}

              <div className="controls">
                {!call && (
                  <>
                    <input
                      type="text"
                      value={remotePeerId}
                      onChange={(e) => setRemotePeerId(e.target.value)}
                      placeholder="Enter Remote Peer ID"
                      className="peer-id-input"
                    />
                    <Button
                      onClick={startCall}
                      className="control-button"
                      variant="primary"
                    >
                      Start Call
                    </Button>
                  </>
                )}

                {call && (
                  <>
                    <div className="d-flex abmu">
                      <img
                        className="endcall"
                        onClick={endCall}
                        src="/Images/endcall.png"
                        alt=""
                      />
                      <Button
                        onClick={toggleVideo}
                        className="control-button but-vi"
                        variant="secondar"
                      >
                        {isVideoMuted ? (
                          <img
                            className="fontic"
                            src="/Images/video-slash.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="fontic"
                            src="/Images/video.png"
                            alt=""
                          />
                        )}
                      </Button>
                      <Button
                        onClick={toggleAudio}
                        className="control-button but-vi"
                        variant="secondar"
                      >
                        {isAudioMuted ? (
                          <img
                            className="fontic"
                            src="/Images/mute.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="fontic"
                            src="/Images/voice.png"
                            alt=""
                          />
                        )}
                      </Button>
                      <Button
                        variant="warning"
                        onClick={
                          isScreenSharing ? stopScreenShare : startScreenShare
                        }
                      >
                        {isScreenSharing ? "Stop Shareing" : "Screen Shareing"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <NavbarModal />
      </Suspense>
    </>
  );
};

export default Navbar;
