import React, { useRef, useEffect, useState, useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import LoadableImage from "../CommonFolder/CommonImages/LoadableImage";
import MyButton from "../CommonFolder/MyButton";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useVideo } from "../../VideoContext";
import { useAudio } from "../../AudioContext";

const Presentation = () => {
  const { playPreloadedVideo, playPreloadedThreeVideos } = useVideo();
  const { playClickSound } = useAudio();

  const [isPlaying, setIsPlaying] = useState(true);
  const { id } = useParams();
  const modalVideoRef = useRef(null);
  const pageVideoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [HidePagevideo, setHidePagevideo] = useState(false);
  const [firstName, setFirstName] = useState("");

  // const play = (audioPath) => {
  //   const audio = new Audio(audioPath);
  //   audio.play().catch((error) => {
  //     console.error("Audio playback failed:", error);
  //   });
  // };
  // const playAudio = () => {
  //   play("/assets/Audios/clicksoundeffect.mp3");
  // };

  useEffect(() => {
    const storedFirstName = localStorage.getItem("firstName");
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
  }, []);

  const handleVideoPlayback = useMemo(() => {
    return () => {
      if (modalVideoRef.current) {
        if (isModalOpen) {
          modalVideoRef.current.play();
          setHidePagevideo(true)
        } else {
          modalVideoRef.current.pause();
        }
      }
    };
  }, [isModalOpen]);

  useEffect(() => {
    handleVideoPlayback();
  }, [handleVideoPlayback]);

  const toggleModalVideoPlay = () => {
    const video = modalVideoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const togglePageVideoPlay = () => {
    const video = pageVideoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const playVideo = () => {
    const video = pageVideoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = pageVideoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = pageVideoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };

  return (
    <>
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 Hero_section p-0 position-relative Presentation_left_box">
              <LoadableImage src="/Images/Group68hero.png" alt="sdsdfsf" />

              <NavLink className="play-Aniamtion" data-bs-toggle="modal" data-bs-target="#InfintyCycleVideo" onClick={() => setIsModalOpen(true)}>
                <img className="VideoPlayImag" src="/assets/Images/OtherImages/VideoPlayImag.png" alt="VideoPlay Image" />
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-12 Content">
              <div>
                <NavLink to={`/dashboard/${id}`} onClick={playClickSound}>
                  Skip presentation <FaArrowRightLong />
                </NavLink>
              </div>
              <div className="Main_text">
                <h1>
                  <span>{firstName}’s </span>Vehicle <br /> Life cycle
                </h1>
                <p>
                  Delivering an unparalleled customer experience <br /> through Cutting-edge Machine Learning.
                </p>
                <NavLink data-bs-toggle="modal" data-bs-target="#InfintyCycleVideo" onClick={() => setIsModalOpen(true)}>
                  <span onClick={playClickSound}> Watch Now</span>
                </NavLink>
                <img src="/Images/LeftArrow.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* here is my popup  */}

        <div className="modal" id="InfintyCycleVideo" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={() => setIsModalOpen(false)}>
          <div className="modal-dialog Waiting_pop Loop_Video_Modal">
            <div className="modal-content border-0 bg-transparent">
              <div className="modal-body p-0">
                <div className="background-video position-static" onClick={toggleModalVideoPlay}>
                  <video className="w-100" id="myVideo" ref={modalVideoRef} autoPlay loop preload="none">
                    <source src="../assets/Videos/PageVideos/carloopvideo2.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="modal-footer border-0 justify-content-center mt-2">
                <NavLink to={`/dashboard/${id}`}>
                  <MyButton title="Let’s Proceed" onClick={toggleModalVideoPlay} className="Secondry_Btn" data-bs-dismiss="modal" aria-label="Close" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        {/* here is versa aia video  */}

       {!HidePagevideo && <div className="DetailVerification_Versa">
          <div className="DetailVerification_Versa_Bottons">
            {isPlaying ? (
              <button onClick={pauseVideo}>
                <FaPause />
              </button>
            ) : (
              <button onClick={playVideo}>
                <FaPlay />
              </button>
            )}
            <button onClick={replayVideo}>
              <MdOutlineReplay />
            </button>
          </div>
          <div className="Bot_Buttons Big-size-background-Ai-video">
            <video ref={pageVideoRef} onClick={togglePageVideoPlay} autoPlay></video>
            <img
              onClick={togglePageVideoPlay}
              className="Big-size-background-Ai"
              src="/assets/Images/DashboardImages/newversaNew.jpg"
              alt=""
              onLoad={() => {
                playPreloadedVideo("infinityVideoSrc", pageVideoRef);
                playPreloadedThreeVideos("../assets/Videos/referSomeone.webm", "../assets/Videos/PageVideos/infinityPage.webm", "../assets/Videos/PageVideos/welcomeToMenu.webm", "referVideoSrc", "infinityVideoSrc", "welcomToMenu");
              }}
            />
          </div>
        </div>}


      </main>
    </>
  );
};

export default Presentation;
