import React from "react";

const TermAmountCalulation = ({ emi, interest, term, down }) => {
  return (
    <>
      <div className="Term_Amount_Items">
        <div>
          <span className="termRateLable">Term/Rate</span>
          <span className="TotalTermRateValue Value">
            {term}
            <span className="RateValue">@ {interest}%</span>
          </span>
        </div>
        <div>
          <span className="RetailPMTLable">Retail pymt</span>
          <span className="RetailPMTValue Value">${emi}</span>
        </div>
        <div>
          <span className="AmtDownLable">Amt down</span>
          <span className="AmtDownValue Value">${down}</span>
        </div>
        <div>
          <span className="DisPMTLable">Disc pymt</span>
          <span className="DisPMTvalue Value">${emi}</span>
        </div>
      </div>
    </>
  );
};

export default TermAmountCalulation;
