import React from "react";

const MenuVersaImage = () => {
  return (
    <>
      <img className="Sign_In_Bottom_Image" src="./Images/SecondMENUVERSE.png" alt="" />
    </>
  );
};

export default MenuVersaImage;
