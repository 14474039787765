import React, { useState, useEffect, useRef } from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import Modal from "../Deals/Modal";
import MyButton from "../CommonFolder/MyButton";
import { NavLink } from "react-router-dom";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useVideo } from "../../VideoContext";
import validationRules from "../CommonFolder/FormStructure/validationRules.json";

const EditDeal = ({ setIsLogin }) => {
  let half = "col-md-6 mb-3 DisabledInputs";
  const { id } = useParams();
  const [isPlaying, setIsPlaying] = useState(true);
  const usertoken = localStorage.getItem("token");
  const navigate = useNavigate();
  const { playPreloadedVideo } = useVideo();

  const [dealData, setDealData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    streetAddress: "",
    city: 0,
    state: 0,
    zipCode: "",
    vehicleColor: "",
    free_add_on: "",
    taxes: "",
    deal_number: "",
    loan_term: "",
    purchase_price: "",
    interest_rate: "",
    down_payment: "",
    vehicle_payment: "",
    net_trade: "",
    protection_items: "",
    rebate: "",
    total_monthly_payment: "",
    vinNumber: "",
  });

  const validateForm = () => {
    const newErrors = {};
    for (const [field, rules] of Object.entries(validationRules)) {
      const value = dealData[field];

      if (rules.required && !value) {
        newErrors[field] = rules.message;
      } else if (rules.minLength && value.replace(/\D/g, "").length < rules.minLength) {
        newErrors[field] = rules.message;
      } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
        newErrors[field] = rules.message;
      } else if (rules.length && value.replace(/\D/g, "").length !== rules.length) {
        newErrors[field] = rules.message;
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://api.menuverse.ai/newdeal/show/${id}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      });
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          localStorage.clear();
          navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setDealData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLoadedData = (videoSrc, ref) => {
    // alert("loaded");
    // setLoading(false);
    playPreloadedVideo(videoSrc, ref);
  };

  const [errors, setErrors] = useState({});

  // const validationRules = {
  //   vehicleColor: { required: true, message: "Vehicle color is required" },
  //   vinNumber: {
  //     required: true,
  //     minLength: 17,
  //     message: "VIN must be 17 characters",
  //   },
  //   // Add other validation rules
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Only allow numbers in InputType="tel"
    if (e.target.type === "tel") {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      setDealData({
        ...dealData,
        [name]: numericValue,
      });
    } else {
      setDealData({
        ...dealData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    // First, run the validation
    const isValid = validateForm();

    if (!isValid) {
      // If the form is not valid, stop the function here
      toast.error("Please correct the errors in the form before submitting.");
      return;
    }

    // If validation passes, proceed with the API call
    try {
      const response = await fetch(`https://api.menuverse.ai/newdeal/update/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(dealData),
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          localStorage.clear();
          navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      toast.dismiss();
      toast.success("Your deal has been updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("There was an error updating the deal. Please try again.");
    }
  };

  const videoRef = useRef(null);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };

  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };
  const playAudio = () => {
    play("/assets/Audios/clicksoundeffect.mp3");
  };

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center ">
            <div className="col-lg-9">
              <div className="Full_Table">
                <TableHeader title="ABC Dealer Group" Monthlyfinance={true} />

                <div className="container Add_Dealer">
                  <div className="row">
                    <InputFiled InputBoxClassname={half} InputType="text" InputName="firstName" lableValue="First name" required value={dealData.firstName} onChange={handleChange} errorMessage={errors.firstName} />
                    {errors.firstName && <p className="error-text">{errors.firstName}</p>}

                    <InputFiled InputBoxClassname={half} InputType="text" InputName="middleName" lableValue="Middle Name" value={dealData.middleName} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="text" InputName="lastName" lableValue="Last name" required value={dealData.lastName} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="phoneNumber" lableValue="Phone number" InputID={"phoneNumber"} required value={dealData.phoneNumber} onChange={handleChange} maxlength="10" errorMessage={errors.phoneNumber} />
                    {errors.phoneNumber && <p className="error-text">{errors.phoneNumber}</p>}

                    <InputFiled InputBoxClassname={half} InputType="email" InputName="email" lableValue="Email" required value={dealData.email} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="text" InputName="streetAddress" lableValue="Street Address" required value={dealData.streetAddress} onChange={handleChange} />
                    <SelectField
                      required
                      className={half}
                      InputName="city"
                      Selectlabel="City"
                      value={dealData.city}
                      onChange={handleChange}
                      options={[
                        { value: 1, label: "New York City" },
                        { value: 2, label: "Los Angeles" },
                        { value: 3, label: "Chicago" },
                        { value: 4, label: "Houston" },
                        { value: 5, label: "Phoenix" },
                        { value: 6, label: "Philadelphia" },
                        { value: 7, label: "San Antonio" },
                        { value: 8, label: "San Diego" },
                        { value: 9, label: "Dallas" },
                        { value: 10, label: "San Jose" },
                        { value: 11, label: "Austin" },
                        { value: 12, label: "Jacksonville" },
                        { value: 13, label: "San Francisco" },
                        { value: 14, label: "Indianapolis" },
                        { value: 15, label: "Seattle" },
                        { value: 16, label: "Denver" },
                        { value: 17, label: "Washington D.C." },
                        { value: 18, label: "Boston" },
                        { value: 19, label: "El Paso" },
                        { value: 20, label: "Detroit" },
                        { value: 21, label: "Nashville" },
                        { value: 22, label: "Memphis" },
                        { value: 23, label: "Portland" },
                        { value: 24, label: "Las Vegas" },
                        { value: 25, label: "Louisville" },
                        { value: 26, label: "Milwaukee" },
                        { value: 27, label: "Baltimore" },
                        { value: 28, label: "Albany" },
                        { value: 29, label: "Columbus" },
                        { value: 30, label: "Cincinnati" },
                        { value: 31, label: "St. Louis" },
                        { value: 32, label: "Kansas City" },
                        { value: 33, label: "Charlotte" },
                        { value: 34, label: "Tampa" },
                        { value: 35, label: "Pittsburgh" },
                        { value: 36, label: "Orlando" },
                        { value: 37, label: "St. Paul" },
                      ]}
                    />

                    <SelectField
                      required
                      className={half}
                      InputName="state"
                      Selectlabel="State"
                      value={dealData.state}
                      onChange={handleChange}
                      options={[
                        { value: 1, label: "Alabama" },
                        { value: 2, label: "Alaska" },
                        { value: 3, label: "Arizona" },
                        { value: 4, label: "Arkansas" },
                        { value: 5, label: "California" },
                        { value: 6, label: "Colorado" },
                        { value: 7, label: "Connecticut" },
                        { value: 8, label: "Delaware" },
                        { value: 9, label: "Florida" },
                        { value: 10, label: "Georgia" },
                        { value: 11, label: "Hawaii" },
                        { value: 12, label: "Idaho" },
                        { value: 13, label: "Illinois" },
                        { value: 14, label: "Indiana" },
                        { value: 15, label: "Iowa" },
                        { value: 16, label: "Kansas" },
                        { value: 17, label: "Kentucky" },
                        { value: 18, label: "Louisiana" },
                        { value: 19, label: "Maine" },
                        { value: 20, label: "Maryland" },
                        { value: 21, label: "Massachusetts" },
                        { value: 22, label: "Michigan" },
                        { value: 23, label: "Minnesota" },
                        { value: 24, label: "Mississippi" },
                        { value: 25, label: "Missouri" },
                        { value: 26, label: "Montana" },
                        { value: 27, label: "Nebraska" },
                        { value: 28, label: "Nevada" },
                        { value: 29, label: "New Hampshire" },
                        { value: 30, label: "New Jersey" },
                        { value: 31, label: "New Mexico" },
                        { value: 32, label: "New York" },
                        { value: 33, label: "North Carolina" },
                        { value: 34, label: "North Dakota" },
                        { value: 35, label: "Ohio" },
                        { value: 36, label: "Oklahoma" },
                        { value: 37, label: "Oregon" },
                        { value: 38, label: "Pennsylvania" },
                        { value: 39, label: "Rhode Island" },
                        { value: 40, label: "South Carolina" },
                        { value: 41, label: "South Dakota" },
                        { value: 42, label: "Tennessee" },
                        { value: 43, label: "Texas" },
                        { value: 44, label: "Utah" },
                        { value: 45, label: "Vermont" },
                        { value: 46, label: "Virginia" },
                        { value: 47, label: "Washington" },
                        { value: 48, label: "West Virginia" },
                        { value: 49, label: "Wisconsin" },
                        { value: 50, label: "Wyoming" },
                      ]}
                    />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputID={"zipCode"} InputName="zipCode" lableValue="Zip code" required maxlength="5" value={dealData.zipCode} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="text" InputName="vehicleColor" lableValue="Vehicle color" disabled value={dealData.vehicleColor} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="VINnumber" lableValue="VIN number" disabled value={dealData.vinNumber} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="FeeAddons" lableValue="Fee + Add ons" disabled value={dealData.free_add_on ? `$    |    ${dealData.free_add_on}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="text" InputName="Vehicle" lableValue="Vehicle" disabled value={dealData.vehicle ? `$    |    ${dealData.vehicle}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Taxes" lableValue="Taxes" disabled value={dealData.taxes ? `$    |    ${dealData.taxes}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Dealnumber" lableValue="Deal number" disabled value={dealData.deal_number ? `$    |    ${dealData.deal_number}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="text" InputName="Loanterm" lableValue="Loan term" disabled value={dealData.deal_number ? `$    |    ${dealData.deal_number}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Purchaseprice" lableValue="Purchase price" disabled value={dealData.purchase_price ? `$    |    ${dealData.purchase_price}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Interestrate" lableValue="Interest rate" disabled value={dealData.interest_rate ? `$    |    ${dealData.interest_rate}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Downpayment" lableValue="Down payment" disabled value={dealData.down_payment ? `$    |    ${dealData.down_payment}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Vehiclepayment" lableValue="Vehicle payment" disabled value={dealData.vehicle_payment ? `$    |    ${dealData.vehicle_payment}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Nettrade" lableValue="Net trade" disabled value={dealData.net_trade ? `$    |    ${dealData.net_trade}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Protectionitems" lableValue="Protection items" disabled value={dealData.protection_items ? `$    |    ${dealData.protection_items}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Rebate" lableValue="Rebate" disabled value={dealData.rebate ? `$    |    ${dealData.rebate}` : ""} onChange={handleChange} />

                    <InputFiled InputBoxClassname={half} InputType="tel" InputName="Totalmonthlypayment" lableValue="Total monthly payment" disabled value={dealData.total_monthly_payment ? `$    |    ${dealData.total_monthly_payment}` : ""} onChange={handleChange} />
                  </div>
                </div>

                <div className="Table_foot ">
                  <div className="Foot_btn pe-5 me-5">
                    <NavLink to={`/DetailVerification/${id}`}>
                      <MyButton title="Cancel" className="Secondry_Btn" />
                    </NavLink>
                    <button className="MyBtn" data-bs-toggle="modal" data-bs-target="#DealConfirmation" onClick={playAudio}>
                      Save & Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal />

        {/*========== here is start shared Successfully!  ========== */}

        <div className="modal" id="DealConfirmation" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog Waiting_pop Changed_Successfully">
            <div className="modal-content border-0 ">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={playAudio}></button>
              <div className="modal-body p-0 text-center ">
                <img className="" src="/Images/caution_icon.png" alt="" />
                <h4>
                  Are you sure you want to <br /> change the details?
                </h4>
              </div>
              <div className="modal-footer border-0 justify-content-center p-0">
                <MyButton title="NO" className="Secondry_Btn" data-bs-dismiss="modal" aria-label="Close" />
                <NavLink to={`/DetailVerification/${id}`} onClick={handleSave}>
                  <MyButton title="yes" data-bs-dismiss="modal" aria-label="Close" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="DetailVerification_Versa">
          <div className="DetailVerification_Versa_Bottons">
            {isPlaying ? (
              <button onClick={pauseVideo}>
                <FaPause />
              </button>
            ) : (
              <button onClick={playVideo}>
                <FaPlay />
              </button>
            )}
            <button onClick={replayVideo}>
              <MdOutlineReplay />
            </button>
          </div>
          <div className="Bot_Buttons Big-size-background-Ai-video">
            <video ref={videoRef} onClick={togglePlay} autoPlay unmuted></video>
            <img
              onClick={togglePlay}
              className="Big-size-background-Ai"
              src="/assets/Images/DashboardImages/newversaNew.jpg"
              alt=""
              onLoad={() => {
                handleLoadedData("dealEditVideoSrc", videoRef);
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default EditDeal;
