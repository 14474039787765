import React, { useEffect, useState, useRef, Suspense } from "react";
import Seviceoptions from "./DashboardParts/DashboardCenterBody/Serviceoptions";
import DashboardLeftSidebar from "./DashboardParts/DashboardLeftSidebarParts/DashboardLeftSidebar";
import "./Dashboard.css";
import Loader from "../CommonFolder/Loader";
import { GiCarWheel } from "react-icons/gi";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import ProductData from "./DashboardParts/DashboardCenterBody/ProductData.json";
import ProductTitle from "./DashboardParts/DashboardCenterBody/ProductTitle.json";
import MyButton from "../CommonFolder/MyButton";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useVideo } from "../../VideoContext";
import DashboardRightSidebar from "./DashboardParts/DashboardRightSidebarParts/DashboardRightSidebar";

// const DashboardRightSidebar = React.lazy(() =>
// import("./DashboardParts/DashboardRightSidebarParts/DashboardRightSidebar");
// );

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const DashvideoRef = useRef(null);
  const videoRefSecond = useRef(null);
  const showModalvideoRef = useRef(null);
  const Dealid = parseInt(window.location.pathname.split("/")[2], 10);
  const usertoken = localStorage.getItem("token");

  const [SecondModalVideo, setSecondModalVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const ModalvideoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState("GAP");
  const [triviaText, setTriviaText] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isDeleteActive, setIsDeleteActive] = useState(true);
  const productRefs = useRef({});
  const [activeDeleteProducts, setActiveDeleteProducts] = useState([]);
  const [ProductShowAll, SetProductShowAll] = useState(true);
  const [selectedReason, setSelectedReason] = useState("");
  const [Opendashboard, setOpendashboard] = useState(false);
  const [cleanedService, setCleanedService] = useState(
    selectedService.replace(/[\s/]+/g, "")
  );

  const handleRadioChange = (event) => {
    setSelectedReason(event.target.value);
  };
  const services = [
    { id: 1, WarrantyImages: "car-crash2.png", ServicesTitle: "GAP" },
    {
      id: 2,
      WarrantyImages: "windshield.png",
      ServicesTitle: "Windshield Protection",
    },
    {
      id: 3,
      WarrantyImages: "remote-control2.png",
      ServicesTitle: "Key/Remote Replacement",
    },
    {
      id: 4,
      WarrantyImages: "tools.png",
      ServicesTitle: "Vehicle Service Contract",
    },
    {
      id: 5,
      WarrantyImages: "car-painting.png",
      ServicesTitle: "Paintless Dent Repair",
    },
    {
      id: 6,
      WarrantyImages: "under-maintenanc1.png",
      ServicesTitle: "Pre-paid Maintenance",
    },
    {
      id: 7,
      WarrantyImages: "anti-theft-system.png",
      ServicesTitle: "Anti-theft",
    },
    {
      id: 8,
      WarrantyImages: "car-painting.png",
      ServicesTitle: "Paint Protection",
    },
    {
      id: 9,
      WarrantyImages: "tierVector.png",
      ServicesTitle: "Tire & Wheel Protection",
    },
    {
      id: 10,
      WarrantyImages: "threelayersbalck.png",
      ServicesTitle: "TotalCare Shield",
      OptionMSG: "3",
    },
  ];

  const service = services.find(
    (service) => service.ServicesTitle === selectedService
  );
  const midpoint = Math.ceil(services.length / 2);
  const topServices = services.slice(0, midpoint);
  const bottomServices = services.slice(midpoint);

  const { playPreloadedVideo } = useVideo();
  useEffect(() => {
    const storedDealIds =
      JSON.parse(localStorage.getItem("openedDealIds")) || [];

    if (!storedDealIds.includes(Dealid)) {
      setShowModal(true);

      storedDealIds.push(Dealid);
      localStorage.setItem("openedDealIds", JSON.stringify(storedDealIds));
    } else {
      console.log("storedDealIds", storedDealIds);
      setOpendashboard(true);
    }
  }, [Dealid]);

  const initalMenu = [
    {
      VSC: {
        price: "00.0",
      },

      GAP: {
        price: "00.0",
      },

      PPM: {
        price: "00.0",
      },

      anti_theft: {
        price: "00.0",
      },

      PDR: {
        price: "00.0",
      },

      windshield_protection: {
        price: "00.0",
      },

      LWT: {
        price: "00.0",
      },

      key_replacement: {
        price: "00.0",
      },

      TWL: {
        price: "00.0",
      },

      paint_protection: {
        price: "00.0",
      },
      TB: {
        price: "00.0",
      },
    },
  ];

  const [menuData, setMenuData] = useState(initalMenu);
  const [emiData, setemiData] = useState(0.0);
  const [second_emiData, setsecond_emiData] = useState(0.0);
  const [interestData, setinterestData] = useState(0.0);
  const [termData, settermData] = useState(0.0);
  const [second_termData, setsecond_termData] = useState(0.0);
  const [downData, setDownData] = useState(0.0);

  const getMenu = async () => {
    const menuResponse = await fetch(
      "https://api.menuverse.ai/menu/show/" + Dealid,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    const result = await menuResponse.json();

    const products = result.data?.menu;
    const emi = result.data?.emi;
    const second_emi = result.data?.second_emi;
    const interest = result.data?.interest;
    const term = result.data?.term;
    const second_term = result.data?.second_term;
    const down = result.data?.down;

    setemiData(emi);
    setsecond_emiData(second_emi);
    setinterestData(interest);
    settermData(term);
    setsecond_termData(second_term);
    setDownData(down);
    if (products) {
      const updated_products = products.reduce((acc, prod) => {
        const key = Object.keys(prod)[0];
        // const key = Object.values(prod)[0][0].category_type;
        console.log(key);

        // const n = { price: Object.values(prod)[0][0]?.sell_price };

        // console.log("val: ", n);
        acc = {
          ...initalMenu[0],
          [key]: {
            price: Object.values(prod)[0][0]?.sell_price,
            name: Object.values(prod)[0][0]?.name,
            miles: Object.values(prod)[0][0]?.miles,
            months: Object.values(prod)[0][0]?.months,
            deductible: Object.values(prod)[0][0]?.deductible,
            service_interval: Object.values(prod)[0][0]?.service_interval,
            tire_rotations: Object.values(prod)[0][0]?.tire_rotations,
            category_type: Object.values(prod)[0][0]?.category_type,
            menu_id: Object.values(prod)[0][0]?.menuId,
            id: Object.values(prod)[0][0]?.id,
          },
        };

        initalMenu[0] = acc;

        return acc;
      }, {});

      // console.log("updated_products: ", menuData);

      setMenuData(updated_products);

      // console.log("updated_products: ", menuData);
    }
  };

  useEffect(() => {
    getMenu();
  }, []);

  const handleSaveMenu = () => {
    // alert("Menu updated");
    getMenu();
  };

  const [isSidebarLoaded, setIsSidebarLoaded] = useState(false);

  useEffect(() => {
    if (showModal && isSidebarLoaded) {
      const video = showModalvideoRef.current;
      if (video) {
        playPreloadedVideo("welcomToMenu", showModalvideoRef);
        // const playVideo = () => {
        //   video.play().catch((error) => {
        //     console.error("Autoplay failed:", error);
        //     // Retry playing the video after a short delay
        //     setTimeout(playVideo, 1000);
        //   });
        // };
        // playVideo();
      }
    }
  }, [isSidebarLoaded]);

  const handleSidebarLoad = () => {
    // alert("loaded");
    setIsSidebarLoaded(true);
  };

  const handleClickshowModal = () => {
    const video = showModalvideoRef.current;
    if (video && video.paused) {
      video.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    } else if (video) {
      video.pause();
    }
  };

  const handleClickClose = () => {
    setShowModal(false);
    setIsModalOpen(true);
    const video = showModalvideoRef.current;
    if (video) {
      video.pause();
    }

    setTimeout(() => {
      // Find the second button by its data-bs-target attribute
      const secondButton = document.querySelector(
        '[data-bs-target="#TotalCareShieldAndComman"]'
      );
      if (secondButton) {
        secondButton.click();
      }
    }, 40);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  const getClassForServices = (length) => {
    switch (length) {
      case 4:
        return "Four";
      case 3:
        return "Three";
      default:
        return "";
    }
  };

  const handleClickVideoWidth = () => {
    play("/assets/Audios/clicksoundeffect.mp3");
    setIsFullWidth(false);
    setSecondModalVideo(false);

    if (ModalvideoRef.current) {
      ModalvideoRef.current.pause();
      ModalvideoRef.current.currentTime = 0;
    }
  };

  const togglePlayPause = () => {
    if (ModalvideoRef.current) {
      if (ModalvideoRef.current.paused) {
        ModalvideoRef.current.play();
      } else {
        ModalvideoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      ModalvideoRef.current.play();
    } else {
      ModalvideoRef.current.pause();
    }
  }, [isModalOpen]);

  const videoWidth = () => {
    setIsFullWidth(!isFullWidth);
  };

  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  const playAudio = () => {
    play("/assets/Audios/clicksoundeffect.mp3");
  };

  useEffect(() => {
    const handleShowModal = () => {
      setIsModalOpen(true);
    };

    const handleHideModal = () => {
      setIsModalOpen(false);
    };

    const modalElement = document.getElementById("TotalCareShieldAndComman");
    modalElement.addEventListener("show.bs.modal", handleShowModal);
    modalElement.addEventListener("hide.bs.modal", handleHideModal);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleShowModal);
      modalElement.removeEventListener("hide.bs.modal", handleHideModal);
    };
  }, []);

  const storedState = localStorage.getItem("state");
  const matchedItem = ProductData.data.find(
    (item) => item.State === storedState
  );

  useEffect(() => {
    if (selectedService) {
      const matchedProductTitle = ProductTitle.data.find(
        (item) => item.Product === selectedService
      );
      setProductTitle(
        matchedProductTitle
          ? matchedProductTitle.Title
          : "No relevant information available for the selected service."
      );
    }
  }, [selectedService]);

  useEffect(() => {
    if (storedState && selectedService) {
      const matchedItem = ProductData.data.find(
        (item) =>
          item.State === storedState &&
          item.Product.toLowerCase() === selectedService.toLowerCase()
      );
      if (matchedItem) {
        setTriviaText(matchedItem.Trivia);
      } else {
        setTriviaText(
          "No relevant information available for the selected service."
        );
      }
    } else {
      setTriviaText(
        "No relevant information available for the selected service."
      );
    }
    const newCleanedService = selectedService.replace(/[\s/]+/g, "");
    setCleanedService(newCleanedService);
  }, [selectedService, storedState]);
  // console.log("selectedService", selectedService);

  const playVideo = () => {
    const video = DashvideoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = DashvideoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = DashvideoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };

  const togglePlay = () => {
    const video = DashvideoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleLoadedData = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const handleProceedClick = () => {
    play("/assets/Audios/clicksoundeffect.mp3");

    const productElement = productRefs.current[selectedProductId];
    if (productElement) {
      productElement.classList.add("DeleteActive");

      setActiveDeleteProducts((prevActiveDeleteProducts) => {
        let updatedProducts = prevActiveDeleteProducts;

        // If the selected product ID is not already in the array, add it
        if (!prevActiveDeleteProducts.includes(selectedProductId)) {
          updatedProducts = [...prevActiveDeleteProducts, selectedProductId];
        }

        // Set ProductShowAll to false if there are products in the array, true if empty
        if (updatedProducts.length == 0) {
          SetProductShowAll(0);
        } else if (updatedProducts.length == 1) {
          SetProductShowAll(1);
        } else {
          SetProductShowAll(2);
        }

        return updatedProducts;
      });

      setIsDeleteActive(false);
    }
  };

  const handleAddAllProtectionsClick = () => {
    setActiveDeleteProducts([]);
    SetProductShowAll(0);

    activeDeleteProducts.forEach((productId) => {
      const productElement = productRefs.current[productId];
      if (productElement) {
        productElement.classList.remove("DeleteActive");
      }
    });

    setIsDeleteActive(true);
  };

  const handleRemoveProduct = (productId) => {
    setActiveDeleteProducts((prevActiveDeleteProducts) => {
      const updatedProducts = prevActiveDeleteProducts.filter(
        (id) => id !== productId
      );

      if (updatedProducts.length === 0) {
        setIsDeleteActive(true);
        SetProductShowAll(0);
      } else if (updatedProducts.length === 1) {
        SetProductShowAll(1);
      } else {
        SetProductShowAll(2);
      }

      return updatedProducts;
    });

    const productElement = productRefs.current[productId];
    if (productElement) {
      productElement.classList.remove("DeleteActive");
    }
  };

  const handleVideoEnd2 = () => {
    setIsPlaying(false);
    setSecondModalVideo(false);
    if (!Opendashboard) {
      // Find the current index of the selected service
      const currentIndex = services.findIndex(
        (service) => service.ServicesTitle === selectedService
      );

      // Calculate the next index (loop back to the first service if at the end)
      const nextIndex = (currentIndex + 1) % services.length;

      // Set the selected service to the next service title
      const nextServiceTitle = services[nextIndex].ServicesTitle;
      setSelectedService(nextServiceTitle);

      // Update the cleanedService for the next video
      const newCleanedService = nextServiceTitle.replace(/[\s/]+/g, "");
      setCleanedService(newCleanedService);

      // console.log("selectedService", nextServiceTitle);

      setTimeout(() => {
        const video = DashvideoRef.current;
        if (video) {
          video.play();
          setIsPlaying(true);
        }
      }, 20);
    }
  };

  const handleVideoEnd = () => {
    setSecondModalVideo(true);

    const video = videoRefSecond.current;
    if (video) {
      video.play();
    }
  };

  const playVideo2 = () => {
    const video = videoRefSecond.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const pauseVideo2 = () => {
    const video = videoRefSecond.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo2 = () => {
    const video = DashvideoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };

  const togglePlay2 = () => {
    const video = videoRefSecond.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <>
      <section id="Dashboard">
        <div className="container-fluid">
          <div className="row">
            <DashboardLeftSidebar ProductShowAll={ProductShowAll} />
            <div className="col-lg-6 py-4 Dashboard-Center-Section">
              <button
                onClick={handleAddAllProtectionsClick}
                className="Add-All-Protections-Btn"
                style={{ display: isDeleteActive ? "none" : "block" }}
              >
                Add all protections
              </button>
              <div
                id={topServices.length === 5 ? "SeviceoptionsTop" : ""}
                className={`Seviceoptions TopOptions ${getClassForServices(
                  topServices.length
                )}`}
              >
                {topServices.map((service, index) => (
                  <Seviceoptions
                    key={index}
                    ProductId={`Top-ProductNo${index}`}
                    WarrantyImages={service.WarrantyImages}
                    ServicesTitle={service.ServicesTitle}
                    OptionMSG={service.OptionMSG}
                    onServiceClick={setSelectedService}
                    ondltproductClick={setSelectedProductId}
                    ref={(el) =>
                      (productRefs.current[`Top-ProductNo${index}`] = el)
                    }
                    onRemoveProduct={handleRemoveProduct}
                  />
                ))}
              </div>
              <div className="Dashboard-Red-Car position-relative">
                {isLoading && (
                  <div className="TLoader">
                    <GiCarWheel />
                    <div className="road"></div>
                  </div>
                )}
                {!hasError && (
                  <img
                    src="/Images/DashboardCar.png"
                    alt="Dashboard Car"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    style={{
                      display: isLoading ? "none" : "inline-block",
                      marginTop: services.length === 10 ? "-4rem" : "",
                      marginBottom: services.length !== 10 ? "2rem" : "",
                    }}
                  />
                )}
              </div>
              <div
                id={bottomServices.length === 5 ? "SeviceoptionsBottom" : ""}
                className={`Seviceoptions bottomOptions ${getClassForServices(
                  bottomServices.length
                )}`}
              >
                {bottomServices.map((service, index) => (
                  <Seviceoptions
                    key={index}
                    ProductId={`Bottom-ProductNo${index}`}
                    WarrantyImages={service.WarrantyImages}
                    ServicesTitle={service.ServicesTitle}
                    OptionMSG={service.OptionMSG}
                    onServiceClick={setSelectedService}
                    ondltproductClick={setSelectedProductId}
                    ref={(el) =>
                      (productRefs.current[`Bottom-ProductNo${index}`] = el)
                    }
                    onRemoveProduct={handleRemoveProduct}
                  />
                ))}
              </div>
            </div>
            <DashboardRightSidebar
              onSidebarLoad={handleSidebarLoad}
              isModalOpen={isModalOpen}
            />
          </div>
        </div>
        {isModalOpen && (
          <div className="DetailVerification_Versa">
            <div className="DetailVerification_Versa_Bottons">
              {isPlaying ? (
                <button onClick={SecondModalVideo ? pauseVideo2 : pauseVideo}>
                  <FaPause />
                </button>
              ) : (
                <button onClick={SecondModalVideo ? playVideo2 : playVideo}>
                  <FaPlay />
                </button>
              )}
              <button onClick={SecondModalVideo ? replayVideo2 : replayVideo}>
                <MdOutlineReplay />
              </button>
            </div>

            <div className="Bot_Buttons Big-size-background-Ai-video">
              {loading && <Loader />}

              <video
                key={cleanedService}
                ref={DashvideoRef}
                onClick={togglePlay}
                autoPlay
                unmuted
                onLoadedData={handleLoadedData}
                onError={handleError}
                onEnded={handleVideoEnd}
                style={{ zIndex: SecondModalVideo ? "1" : "2" }}
                preload="none"
              >
                <source
                  src={`../assets/Videos/DashboardProductVideo/${cleanedService}.webm`}
                  type="video/webm"
                />
              </video>

              <video
                style={{ zIndex: SecondModalVideo ? "3" : "1" }}
                className="w-100 Double_Videos"
                id="myVideo"
                ref={videoRefSecond}
                onClick={togglePlay2}
                onEnded={handleVideoEnd2}
                preload="none"
              >
                <source
                  src="../assets/Videos/First-Modal-video.webm"
                  type="video/webm"
                />
              </video>

              <img
                onClick={SecondModalVideo ? togglePlay2 : togglePlay}
                className="Big-size-background-Ai"
                src="/assets/Images/DashboardImages/newversaNew.jpg"
                alt=""
              />
            </div>
          </div>
        )}
      </section>
      {/*========== here is start AddDealModal ========== */}

      <div
        className={`modal ${showModal ? "show" : ""}`}
        id="AddDealModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog Waiting_pop">
          <div className="modal-content border-0 bg-transparent">
            <div className="modal-body p-0">
              <div
                className="background-video position-static Dashboard_Modal_Video"
                onClick={handleClickshowModal}
              >
                <div>
                  <video
                    className="w-100 Double_Videos"
                    id="myVideo"
                    ref={showModalvideoRef}
                  ></video>
                  <img
                    src="/assets/Images/DashboardImages/newversaNew.jpg"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center mt-2">
              <button className="Secondry_Btn" onClick={handleClickClose}>
                Continue
              </button>
              <button
                className="d-none"
                data-bs-toggle="modal"
                data-bs-target="#TotalCareShieldAndComman"
              >
                hide
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start TotalCareShieldAndComman ========== */}

      <div
        className="modal"
        id="TotalCareShieldAndComman"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg DashboardModals TotalCareShieldAndComman">
          <div className="modal-content p-0">
            <div className="modal-header m-2">
              <div className="d-flex gap-2 align-items-center">
                <img
                  className=""
                  src={`/assets/Images/DashboardImages/${
                    service ? service.WarrantyImages : "default.png"
                  }`}
                  alt={selectedService}
                />

                <h5 className="modal-title">{selectedService}</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClickVideoWidth}
              ></button>
            </div>
            <div className="modal-body px-4">
              <div className="container-fluid">
                <div className="row">
                  <div
                    className={`col-lg-6 col-md-12 p-0 LeftModalContent ${
                      isFullWidth ? "col-lg-12 order-2" : "col-lg-6"
                    }`}
                  >
                    <div className="LeftModal-Scrollar">
                      <p>{productTitle}</p>
                      <p>{triviaText}</p>
                    </div>
                  </div>
                  <div
                    className={`col-md-12 p-0 RightModalContent ${
                      isFullWidth ? "col-lg-12 order-1" : "col-lg-6"
                    }`}
                  >
                    <div className="video-Fullscreen-box">
                      <video
                        ref={ModalvideoRef}
                        src="/assets/Videos/-3657-4dd7-8ada-962df39395de.mp4"
                        onClick={togglePlayPause}
                        preload="none"
                      />
                      <span
                        className="video-Fullscreen-button"
                        onClick={videoWidth}
                      >
                        {isFullWidth ? <BsFullscreenExit /> : <BsFullscreen />}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 Frequently_bought_Section py-4">
              <h6>Frequently bought together</h6>
              <div className="Frequently_Options">
                {/* 1st modal product */}
                <div className="option-With-Text">
                  <div className="Modal-options">
                    <div className="Option">
                      <img
                        src="../assets/Images/DashboardImages/tierVector.png"
                        alt=""
                      />
                    </div>
                    <p>Tire &amp; Wheel Protection</p>
                  </div>
                </div>
                <p className="plus">+</p>
                {/* 2nd modal product */}

                <div className="option-With-Text">
                  <div className="Modal-options">
                    <div className="Option">
                      <img
                        src="../assets/Images/DashboardImages/tierVector.png"
                        alt=""
                      />
                    </div>
                    <p>Tire &amp; Wheel Protection</p>
                  </div>
                </div>
                <label className="custom-checkbox">
                  <input type="checkbox" onClick={playAudio} />
                  <span className="custom-checkmark"></span>
                  Add these products
                </label>
              </div>
            </div>

            <div className="modal-footer mx-2 py-4 gap-2 justify-content-center flex-md-nowrap">
              <MyButton
                className="w-100 Danger-Button"
                title="Remove it, I want to Risk my car"
                data-bs-toggle="modal"
                data-bs-target="#WhatReason"
              ></MyButton>
              <MyButton
                className="w-100 MyBtn"
                title="Keep it, I want to Protect my car"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start WhatReason ========== */}

      <div
        className="modal"
        id="WhatReason"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals">
          <div className="modal-content border-0 WhatReason">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playAudio}
              ></button>
              <h4>What’s the reason you don’t want to buy this product?</h4>
            </div>
            <div className="modal-body p-0 text-center ">
              <div className="Select_Reason">
                <div>
                  <input
                    type="radio"
                    name="RiskReason"
                    className="form-check-input"
                    onChange={handleRadioChange}
                  />
                  <label>Price</label>
                </div>

                <span>You still have discount coupons</span>
              </div>
              <div className="Select_Reason">
                <div>
                  <input
                    type="radio"
                    name="RiskReason"
                    className="form-check-input"
                    onChange={handleRadioChange}
                  />
                  <label>Coverage</label>
                </div>
              </div>
              <div className="Select_Reason">
                <div>
                  <input
                    type="radio"
                    name="RiskReason"
                    className="form-check-input"
                    onChange={handleRadioChange}
                  />
                  <label>Other</label>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                className="w-100 MyBtn"
                title="Submit"
                data-bs-toggle="modal"
                data-bs-target="#Vehicle_Risk"
                disabled={!selectedReason}
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Vehicle_Risk ========== */}

      <div
        className="modal"
        id="Vehicle_Risk"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={playAudio}
            ></button>
            <div className="modal-body p-0 text-center ">
              <>
                <img
                  className=""
                  src="/Images/caution_icon.png"
                  alt="Success"
                />
                <h4>You’re vehicle is at risk </h4>
                <p>
                  Based on your choice to decline, you may have a potential
                  ownership lifetime exposure of $3768.{" "}
                </p>
              </>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                className="Empty_Btn border-0"
                title="Cancel"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
              <button
                className="MyBtn"
                onClick={handleProceedClick}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
