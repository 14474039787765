import React, { useEffect, useState } from "react";

const QuestionnaireHeader = ({ questionNumber }) => {
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    // Fetch the first name from localStorage
    const storedFirstName = localStorage.getItem("firstName");
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
  }, []);
  
  const renderHrTags = () => {
    const hrTags = [];
    for (let i = 0; i < questionNumber; i++) {
      hrTags.push(<hr key={i} />);
    }
    return hrTags;
  };

  return (
    <>
      <div className="header">
        <h4>
          <span>{firstName}</span> ownership journey powered by AI
        </h4>
        <div className="Hr_Box"> {renderHrTags()}</div>
      </div>
    </>
  );
};

export default QuestionnaireHeader;
