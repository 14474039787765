import React from "react";

const ABCDealerImage = () => {
  return (
    <>
      <img className="Login_logo" src="./Images/Group34011.png" alt="" />
    </>
  );
};

export default ABCDealerImage;
