import React, { useState } from "react";
import InputFiled from "../../CommonFolder/FormStructure/InputField";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SendCode = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/users/forgot-password",
        {
          email: email,
        }
      );

      if (response.status === 200) {
        navigate("/VerifyCode");
      }
    } catch (error) {
      // Handle error
      setError("Failed to send code. Please try again.");
      console.error("API error:", error);
    }
  };

  localStorage.setItem("email", email);

  return (
    <>
      <form className="row g-3 w-100" onSubmit={handleSubmit}>
        <div className=" Sign_In_Box">
          <InputFiled
            InputBoxClassname="col-md-12 mb-3"
            InputType="email"
            InputName="FirstName"
            Inputplaceholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && <p className="text-danger">{error}</p>}
          <button className="w-100 MyBtn" type="submit">
            Send Code
          </button>
        </div>
      </form>
    </>
  );
};

export default SendCode;
