import React from "react";

const RadioField = ({ InputType, InputName, InputID, disabled, Radiovalue }) => {
  return (
    <>
      <div>
        <input type={InputType} id={InputID} name="Charity" className="form-check-input" disabled={disabled} />
        <span>{Radiovalue}</span>
      </div>
    </>
  );
};

export default RadioField;
