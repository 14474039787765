import React, { forwardRef } from "react";
import "../../Dashboard.css";
import { NavLink } from "react-router-dom";
import { HiPlus } from "react-icons/hi2";

const Seviceoptions = forwardRef(({ ProductId, WarrantyImages, ServicesTitle, OptionMSG, onServiceClick, ondltproductClick, onRemoveProduct }, ref) => {
  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  const handleClick = () => {
    play("/assets/Audios/clicksoundeffect.mp3");
    onServiceClick(ServicesTitle);
    ondltproductClick(ProductId);
  };

  const AddProduct = (event) => {
    play("/assets/Audios/clicksoundeffect.mp3");
    event.stopPropagation();
    onRemoveProduct(ProductId);
  };

  return (
    <>
      <div ref={ref} className={`option-With-Text ${ProductId}`} onClick={handleClick}>
        <HiPlus className="Add-Product-Icon" onClick={AddProduct} />
        <NavLink data-bs-toggle="modal" data-bs-target="#TotalCareShieldAndComman">
          <div className="Option clickTarget">
            <img className="clickTarget" src={`/assets/Images/DashboardImages/${WarrantyImages}`} alt="" />
            <span className="clickTarget">{OptionMSG}</span>
          </div>
          <p>{ServicesTitle}</p>
        </NavLink>
      </div>
    </>
  );
});

export default Seviceoptions;
