import React, { useState, useRef, useContext , useEffect } from "react";
import "../../Dashboard.css";
import ProductItems from "./ProductItems";
import TermAmountCalulation from "./TermAmountCalulation";
import MyButton from "../../../CommonFolder/MyButton";
import SignatureCanvas from "react-signature-canvas";
import InputFiled from "../../../CommonFolder/FormStructure/InputField";
import { AudioContext } from "../../../../App";
import { io } from "socket.io-client";

const DashboardLeftSidebar = ({
  menuData,
  emiData,
  second_emiData,
  interestData,
  termData,
  second_termData,
  downData,
  onSaveMenu,
  ProductShowAll,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [InputChecked, setInputChecked] = useState(false);
  const sigCanvas = useRef({});
  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };
  const playAudio = () => {
    play("/assets/Audios/clicksoundeffect.mp3");
  };
  // console.log("menu", menuData);
  if (Array.isArray(menuData)) {
    menuData = menuData[0];
  }

  const socket = useRef(null);

  const customer_Id = localStorage.getItem('userId');
  const isAdmin = localStorage.getItem('controller');

  useEffect(() => {
    // Initialize socket connection
    socket.current = io('http://localhost:3000');

    // Store the userId in socket on connection
    if (customer_Id) {
      socket.current.emit('storeUserId', customer_Id);
    }

    // Listen for state updates for this specific user
    socket.current.on('updateUserState', ({ userId, newState }) => {
      if (customer_Id === userId) {
        setIsChecked(newState);
      }
    });

    return () => {
      socket.current.disconnect();
    };
  }, [customer_Id]);

  // Admin triggering state toggle for specific user
  const handleAdminToggle = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    playAudio();
    
    // Emit the state change for the specific user
    socket.current.emit('toggleStateForUser', { userId: customer_Id, newState });
  };

  const handleClickauio = () => {
    playAudio();
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const InputCheckboxChange = () => {
    setInputChecked(!isChecked);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const handleSaveMenu = () => {
    if (onSaveMenu) {
      onSaveMenu();
    }
  };
  const content =
    ProductShowAll === 1 ? (
      <div>
        <p>
          Your vehicle is partially Protected. Make it fully secured by
          completing your coverage.
        </p>
      </div>
    ) : ProductShowAll === 2 ? (
      <div>
        <p>
          Secure your peace of mind on the road. Upgrade to below warranties.
        </p>
      </div>
    ) : (
      <div>
        <p>Congratulations! </p>
        <p>Your vehicle is fully Protected.</p>
      </div>
    );

  return (
    <>
      <div
        className={`col-lg-3 Dashboard-Left-Sidebar ${
          ProductShowAll === 1
            ? "Bg-Warning"
            : ProductShowAll === 2
            ? "Bg-Danger"
            : ""
        }`}
      >
        <div className="head">
          {ProductShowAll === 1 ? (
            <img
              src="/assets/Images/DashboardImages/triangle-warning.png"
              alt=""
            />
          ) : ProductShowAll >= 2 ? (
            <img
              src="/assets/Images/DashboardImages/triangle-danger.png"
              alt=""
            />
          ) : ProductShowAll === 0 ? (
            <img src="/assets/Images/DashboardImages/checkVector.png" alt="" />
          ) : (
            <img src="/assets/Images/DashboardImages/checkVector.png" alt="" />
          )}

          <div>{content}</div>
        </div>
        {/*========== here is start green sidebar ========== */}

        <div className="Product-Options">
          <div>
            <div className="Recommend_Select_Options">
              {!isChecked && (
                <p>
                  Lock in Peace of Mind: Explore <br /> Our Vehicle Product
                  Options
                </p>
              )}
              {isChecked && (
                <p>
                  <span>VERSA</span> recommendations based on your custom
                  ownership journey
                </p>
              )}

              {isAdmin == "admin"  ? (
              <div className="Versa_Recommends">
                <div>
                  <span>VERSA</span>
                  <p>Recommends</p>
                </div>
                <label className="custom-checkbox">
                <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => {}}
                      onClick={handleAdminToggle} // Admin triggers the state change
                    />
                  {/* <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} onClick={handleClickauio} /> */}
                  <span className="custom-checkmark"></span>
                </label>
              </div>) : null }
            </div>
            <div className="Product-Options-Items row row-cols-2  gy-3">
              <ProductItems
                ProductItemsTitle="VSC"
                ProductItemsPrice={menuData?.VSC?.price ?? "47.00"}
                ProuctImage="tools.png"
                AcDeactive={isChecked ? "Deactive" : ""}
                productDetail={menuData?.VSC}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                Option_Logo={isChecked}
                ProductItemsTitle="GAP"
                ProductItemsPrice={menuData?.GAP?.price ?? "26.54"}
                ProuctImage="car-crash2.png"
                productDetail={menuData?.GAP}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                Option_Logo={isChecked}
                ProductItemsTitle="PPM"
                ProductItemsPrice={menuData?.PPM?.price ?? "11.00"}
                ProuctImage="under-maintenanc1.png"
                productDetail={menuData?.PPM}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                ProductItemsTitle="Anti-theft"
                ProductItemsPrice={menuData?.anti_theft?.price ?? "0.00"}
                ProuctImage="anti-theft-system.png"
                AcDeactive={isChecked ? "Deactive" : ""}
                productDetail={menuData?.anti_theft}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                Option_Logo={isChecked}
                ProuctImage="car-painting.png"
                ProductItemsTitle="PDR"
                ProductItemsPrice={menuData?.PDR?.price ?? "14.00"}
                productDetail={menuData?.PDR}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                AcDeactive={isChecked ? "Deactive" : ""}
                ProuctImage="windshield.png"
                ProductItemsTitle="Windshield Protection"
                ProductItemsPrice={
                  menuData?.windshield_protection?.price ?? "8.99"
                }
                productDetail={menuData?.windshield_protection}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                AcDeactive={isChecked ? "Deactive" : ""}
                ProuctImage="threelayersbalck.png"
                ProductItemsTitle="TotalCase Bundle"
                ProductItemsPrice={menuData?.TB?.price ?? "55.00"}
                productDetail={menuData?.TB}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                Option_Logo={isChecked}
                ProuctImage="tierVector.png"
                ProductItemsTitle="TWL"
                ProductItemsPrice={menuData?.TWL?.price ?? "12.00"}
                productDetail={menuData?.TWL}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                AcDeactive={isChecked ? "Deactive" : ""}
                ProuctImage="tools.png"
                ProductItemsTitle="Key-Replacement"
                ProductItemsPrice={menuData?.key_replacement?.price ?? "6.00"}
                productDetail={menuData?.key_replacement}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />

              <ProductItems
                AcDeactive={isChecked ? "Deactive" : ""}
                ProuctImage="threelayersbalck.png"
                ProductItemsTitle="LWT"
                ProductItemsPrice={menuData?.LWT?.price ?? "0.00"}
                productDetail={menuData?.LWT}
                interestData={interestData}
                termData={termData}
                second_termData={second_termData}
                onSaveMenu={handleSaveMenu}
              />
            </div>
          </div>
          <div className="Left_Sidebar_Footer">
            <div className="Term_Amount_Calulation">
              {" "}
              <TermAmountCalulation
                emi={emiData ?? "620.80"}
                interest={interestData ?? "8"}
                term={termData ?? "72"}
                down={downData ?? "0.00"}
              />
              <TermAmountCalulation
                emi={second_emiData ?? "780.90"}
                interest={interestData ?? "60"}
                term={second_termData ?? "8"}
                down={downData ?? "0.00"}
              />
            </div>
            <button
              className="Sign_And_Verify"
              data-bs-toggle="modal"
              data-bs-target="#ESignature"
              onClick={playAudio}
            >
              Sign & Verify
            </button>
          </div>
        </div>
      </div>

      {/*========== here is start modal ESignature ========== */}

      <div
        className="modal"
        id="ESignature"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ESignatureMoadal">
          <div className="modal-content border-0">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playAudio}
              ></button>

              <img
                src="/assets/Images/DashboardImages/E-signatureimg.png"
                alt=""
              />
              <h4>E-signature</h4>
            </div>
            <div className="modal-body text-center p-0">
              <div className="ESignatureTextarea">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <a onClick={clearSignature}>Clear</a>
              </div>

              <div className="Checkbox_input">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={InputChecked}
                  onChange={InputCheckboxChange}
                  onClick={playAudio}
                />
                <span>I agree with all my purchases.</span>
              </div>
            </div>
            <div className="modal-footer  justify-content-center">
              <MyButton
                className="Cancel_Btn"
                title="Cancel"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <MyButton className=" WetSign_Btn" title="Wet Sign" />
              <MyButton
                className=" MyBtn"
                title="Accept E-Sign"
                data-bs-toggle="modal"
                data-bs-target="#GREATJOB"
                disabled={!InputChecked}
              />
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start modal GREATJOB ========== */}

      <div
        className="modal"
        id="GREATJOB"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={playAudio}
            ></button>
            <div className="modal-body p-0 text-center ">
              <img
                className=""
                src="/Images/dislike_image.png"
                alt="GREATJOB"
              />
              <h4>Great Job!</h4>
              <p>
                We encourage you to share the final copy to your email/phone no.
                rather than printing it to save trees.
              </p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                className="Empty_Btn border-0"
                title="Print"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
              <MyButton
                className="bgColor_Btn"
                title="View"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
              <MyButton
                title="Share"
                data-bs-toggle="modal"
                data-bs-target="#Share_Policies"
              ></MyButton>
            </div>
          </div>
          <div className="GREATJOB_Message">
            <p>
              Save 2 pounds of wood by choosing “SHARE” option. This will
              eventually help to reduce carbon footprints and save our mother
              earth.
            </p>
          </div>
        </div>
      </div>

      {/*========== here is start modal Share Policies ========== */}

      <div
        className="modal"
        id="Share_Policies"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals p-4">
          <div className="modal-content p-0 border-0 Edit_Product">
            <div className="modal-header justify-content-start gap-2 p-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playAudio}
              ></button>
              <img src="../Images/tools.png" alt="" />
              <h4>Share Policies</h4>
            </div>
            <div className="modal-body p-4">
              <div className="gap-3 d-flex flex-column">
                <InputFiled
                  lableValue="Email address"
                  InputType="emial"
                  Inputplaceholder="cynthiaj@gmail.com"
                />

                <InputFiled
                  lableValue="Phone number"
                  InputType="number"
                  Inputplaceholder="(925) 676-0200"
                />
              </div>
            </div>
            <div className="modal-footer justify-content-center p-4">
              <MyButton
                className="w-100 MyBtn"
                title="Share Now"
                data-bs-toggle="modal"
                data-bs-target="#Policy_Shared"
              ></MyButton>
            </div>
          </div>
          <div className="GREATJOB_Message">
            <p>
              You’ve unlocked 300 reward points that you can utilize when you
              come again for your vehicle service. Don’t forget to remind us.
            </p>
          </div>
        </div>
      </div>

      {/*========== here is start modal Policy Shared ========== */}

      <div
        className="modal"
        id="Policy_Shared"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={playAudio}
            ></button>
            <div className="modal-body p-0 text-center ">
              <img
                className=""
                src="/Images/dislike_image.png"
                alt="GREATJOB"
              />
              <h4>Policy Shared</h4>
              <p>
                A link has been shared on your email and phone number. You can
                download the policy by clicking on that link
              </p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                title="Share Review"
                data-bs-toggle="modal"
                data-bs-target="#Reviewsmodal"
              ></MyButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLeftSidebar;
