import React, { useState, useEffect, useRef } from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import MyButton from "../CommonFolder/MyButton";
import "./Deals.css";
import "../CommonFolder/DealsTableStructure/Table.css";
import { NavLink, useParams } from "react-router-dom";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import Loader from "../CommonFolder/Loader";
import Modal from "./Modal";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useVideo } from "../../VideoContext";
import { useAudio } from "../../AudioContext";

const DetailVerification = ({ setIsLogin }) => {
  const half = "col-md-6 mb-3";
  const [dealData, setDealData] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);
  const usertoken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isMac, setIsMac] = useState(false);

  const { playPreloadedVideo } = useVideo();
  const { playClickSound } = useAudio();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/Macintosh/.test(userAgent)) {
      setIsMac(true);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://api.menuverse.ai/newdeal/show/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          localStorage.clear();
          navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setDealData(data.data);
      saveFirstNameToLocalStorage(data.data.firstName);
      saveEmailToLocalStorage(data.data.email);
      // saveStateToLocalStorage(data.data.state);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const saveFirstNameToLocalStorage = (firstName) => {
    localStorage.setItem("firstName", firstName);
  };

  const saveEmailToLocalStorage = (email) => {
    localStorage.setItem("DealUserEmail", email);
  };

  const state = "Washington";

  localStorage.setItem("state", state);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying(true);
    }
  };

  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener("ended", handleVideoEnd);

      return () => {
        video.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, []);

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  // const play = (audioPath) => {
  //   const audio = new Audio(audioPath);
  //   audio.play().catch((error) => {
  //     console.error("Audio playback failed:", error);
  //   });
  // };

  const handleContinueClick = () => {
    playClickSound();
    setIsModalOpen(true);
    setIsPlaying(true);
    handleLoadedData("readDisclaimerVideoSrc", videoRef);
  };

  const handleModalClose = () => {
    // alert("modal closed");
    setIsModalOpen(false);
    handleLoadedData("dealDetailVideoSrc", videoRef);
  };

  useEffect(() => {
    const modalElement = document.getElementById("DisclaimerModal");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);

      return () => {
        modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
      };
    }
  }, []);

  const handleLoadedData = (videoSrc, ref) => {
    // alert("loaded");
    setLoading(false);
    playPreloadedVideo(videoSrc, ref);
  };

  const handleError = () => {
    setLoading(false);
  };

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="Full_Table Detail_Verification_Table">
                <TableHeader title="ABC Dealer Group" Monthlyfinance={true} />

                <div className="Full_Detail_Verification_Table">
                  <div className="container Add_Dealer">
                    {loading ? (
                      <div className="loader_Section">
                        <Loader />
                      </div>
                    ) : (
                      <div className="row">
                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="CustomerName"
                          lableValue="Customer name:"
                          value={
                            dealData
                              ? `${dealData.firstName} ${dealData.middleName} ${dealData.lastName}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="VehicleColor"
                          lableValue="Vehicle Color"
                          value={dealData ? dealData.vehicleColor : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="PhoneNumber"
                          lableValue="Phone number"
                          value={dealData ? dealData.phoneNumber : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="FeeAddons"
                          lableValue="Fee + Add-ons:"
                          value={
                            dealData && dealData.free_add_on
                              ? `$ ${dealData.free_add_on}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="email"
                          InputName="Email"
                          lableValue="Email:"
                          value={dealData ? dealData.email : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Taxes"
                          lableValue="Taxes:"
                          value={
                            dealData && dealData.taxes
                              ? `$ ${dealData.taxes}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Address"
                          lableValue="Address:"
                          value={dealData ? dealData.streetAddress : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="LoanTerm"
                          lableValue="Loan term:"
                          value={
                            dealData && dealData.loan_term
                              ? `${dealData.loan_term} Months`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="VINnumber"
                          lableValue="VIN number:"
                          value={dealData ? dealData.vinNumber : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="InterestRate"
                          lableValue="Interest rate:"
                          value={
                            dealData && dealData.interest_rate
                              ? `${dealData.interest_rate} %`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="DealNumber"
                          lableValue="Deal number:"
                          value={dealData ? dealData.deal_number : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="PreviousVehiclepayment"
                          lableValue="Previous Vehicle payment:"
                          value="0"
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Purchaseprice"
                          lableValue="Purchase price:"
                          value={
                            dealData && dealData.purchase_price
                              ? `$ ${dealData.purchase_price}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="NewVehiclepayment"
                          lableValue="New Vehicle payment:"
                          value={
                            dealData && dealData.vehicle_payment
                              ? `$ ${dealData.vehicle_payment}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="DownPayment"
                          lableValue="Down payment:"
                          value={
                            dealData && dealData.down_payment
                              ? `$ ${dealData.down_payment}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="ProtectionItems"
                          lableValue="Protection items:"
                          value={
                            dealData && dealData.protection_items
                              ? `$ ${dealData.protection_items}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="NetTrade"
                          lableValue="Net trade:"
                          value={
                            dealData && dealData.net_trade
                              ? `$ ${dealData.net_trade}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="TotalMonthlyPayment"
                          lableValue="Total monthly payment:"
                          value={
                            dealData && dealData.total_monthly_payment
                              ? `$ ${dealData.total_monthly_payment}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Rebate"
                          lableValue="Rebate:"
                          value={
                            dealData && dealData.rebate
                              ? `$ ${dealData.rebate}`
                              : ""
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="Table_foot pe-5">
                    <div className="Foot_btn pe-5">
                      <button
                        className="Secondry_Btn"
                        data-bs-toggle="modal"
                        data-bs-target="#DisclaimerModal"
                        onClick={handleContinueClick}
                      >
                        Continue
                      </button>

                      <NavLink to={`/EditDeal/${id}`}>
                        <MyButton title="Edit details" />
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div
                  className={`DetailVerification_Versa ${
                    isMac ? "mac_DetailVerification_Versa" : ""
                  }`}
                >
                  <div className="DetailVerification_Versa_Bottons">
                    {isPlaying ? (
                      <button onClick={pauseVideo}>
                        <FaPause />
                      </button>
                    ) : (
                      <button onClick={playVideo}>
                        <FaPlay />
                      </button>
                    )}
                    <button onClick={replayVideo}>
                      <MdOutlineReplay />
                    </button>
                  </div>

                  <div className="Bot_Buttons Big-size-background-Ai-video">
                    {loading && <Loader />}
                    {
                      <>
                        <video
                          key="video1"
                          ref={videoRef}
                          onClick={togglePlay}
                          autoPlay
                          unmuted
                          onError={handleError}
                        ></video>
                        <img
                          onClick={togglePlay}
                          className="Big-size-background-Ai"
                          src="/assets/Images/DashboardImages/newversaNew.jpg"
                          alt=""
                          onLoad={() => {
                            handleLoadedData("dealDetailVideoSrc", videoRef);
                          }}
                        />
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal />
      </section>
    </>
  );
};

export default DetailVerification;
