import React, { useState, useEffect, useRef } from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import Modal from "../Deals/Modal";
import MyButton from "../CommonFolder/MyButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Loader from "../CommonFolder/Loader";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { useAudio } from "../../AudioContext";
import { useVideo } from "../../VideoContext";
import { MdRemoveRedEye } from "react-icons/md";
import { HiShare } from "react-icons/hi";
import { RiDeleteBin2Fill } from "react-icons/ri";

const Deals = ({ setIsLogin }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [ShareDealId, setShareDealId] = useState(null);
  const [dealToDelete, setDealToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const usertoken = localStorage.getItem("token");
  const [isMac, setIsMac] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const [selectedValueForWaiting, setselectedValueForWaiting] = useState(10);
  const { playClickSound } = useAudio();
  const { playPreloadedVideo } = useVideo();

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setselectedValueForWaiting(value);
    localStorage.setItem("selectedValueForWaiting", value);
  };

  const navigate = useNavigate();
  const videoRef = useRef(null);
  let half = "col-md-12 mb-3";
  const [filters, setFilters] = useState({
    dealType: "AllDeal",
    classType: "AllClass",
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    console.log("Selected Deal Type:", newFilters.dealType);
    console.log("Selected Class:", newFilters.classType);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/Macintosh/.test(userAgent)) {
      setIsMac(true);
    }
  }, []);

  useEffect(() => {
    // Calculate rows per page based on the height of the viewport
    const calculateRowsPerPage = () => {
      const availableHeight = window.innerHeight - 343; // 100vh - 343px
      const rows = Math.floor(availableHeight / 55); // Divide by row height (50px)
      setRowsPerPage(rows > 0 ? rows : 5); // Ensure at least 5 row is shown
      console.log("availableHeight", availableHeight)
    };

    calculateRowsPerPage(); // Run on initial load
    window.addEventListener("resize", calculateRowsPerPage); // Recalculate on window resize

    return () => {
      window.removeEventListener("resize", calculateRowsPerPage);
    };
  }, []);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://api.menuverse.ai/newdeal/show", {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            localStorage.clear();
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        setData(responseData.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        navigate("/login");
      });
  }, []);

  useEffect(() => {
    // First filter by search term
    let filtered = data.filter((deal) =>
      `${deal.firstName} ${deal.lastName} ${deal.vinNumber} ${deal.vehicle} ${deal.vehicleColor} ${deal.phoneNumber}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    // Then filter by dealType and classType
    if (filters.dealType !== "AllDeal") {
      filtered = filtered.filter(
        (deal) =>
          deal.deal_type &&
          deal.deal_type.toLowerCase() === filters.dealType.toLowerCase()
      );
    }

    if (filters.classType !== "AllClass") {
      filtered = filtered.filter(
        (deal) =>
          deal.class &&
          deal.class.toLowerCase() === filters.classType.toLowerCase()
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchTerm, data, filters]);

  const handlePageChange = (event, value) => {
    playClickSound();
    setCurrentPage(value);
  };

  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  const handleClick = () => {
    const video = videoRef.current;
    if (video) {
      if (video) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleDelete = (id) => {
    playClickSound();
    fetch(`https://api.menuverse.ai/newdeal/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.message === "new deal deleted successfully") {
          setData(data.filter((deal) => deal.id !== id));
          setFilteredData(filteredData.filter((deal) => deal.id !== id));
        }
      })
      .catch((error) => console.error("Error deleting data:", error));

    navigate("/login");
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const startIdx = (currentPage - 1) * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;
  const paginatedData = filteredData.slice(startIdx, endIdx);

  const handleNavigate = () => {
    navigate(`/DetailVerification/${selectedDealId}`);
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleShown = () => {
      // if (video) {
      //   video.play();
      // }
      playPreloadedVideo("video1", videoRef);
    };

    const handleHidden = () => {
      // if (video) {
      //   video.pause();
      //   video.currentTime = 0;
      // }
    };

    const modalElement = document.getElementById("DealVideoModal");
    if (modalElement) {
      modalElement.addEventListener("shown.bs.modal", handleShown);
      modalElement.addEventListener("hidden.bs.modal", handleHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("shown.bs.modal", handleShown);
        modalElement.removeEventListener("hidden.bs.modal", handleHidden);
      }
    };
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // here is my send mail code start ========================>

  const [email, setEmail] = useState("");
  const userId = localStorage.getItem("userId");

  const closemodal = () => {
    const modalCloseButton = document.querySelector("#mailmodal");
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  };

  const sendEmail = (e) => {
    const isShared = "true";
    const userName = localStorage.getItem("userName");
    e.preventDefault();
    const templateParams = {
      from_name: `${data.find((deal) => deal.id === ShareDealId)?.firstName} ${
        data.find((deal) => deal.id === ShareDealId)?.lastName
      }`,
      Email_to: email,
      From_email: "tester5kwt@gmail.com",
      site_link: `https://staging.menuverse.ai/DetailVerification/${ShareDealId}?=${usertoken}?=${userId}?=${userName}?=${isShared}`,
    };

    emailjs
      .send(
        "service_1pgyq2k",
        "template_pgry7gb",
        templateParams,
        "310uxmyu5Xn5Ntdzt"
      )
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        toast.dismiss();
        toast.success("Link shared Successfully!");
        setEmail("");
        closemodal();
      })
      .catch((err) => {
        console.error("Failed to send email.", err);
        toast.dismiss();
        toast.error("Failed to share the link. Please try again.");
      });
  };

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="Full_Table">
                <TableHeader
                  title="All Deals"
                  showFilters={true}
                  onSearch={handleSearch}
                  onFilterChange={handleFilterChange}
                />
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Deal #</th>
                      <th scope="col">Customer name</th>
                      <th scope="col">Vin Number</th>
                      <th scope="col">Vehicle</th>
                      <th scope="col">Vehicle Color</th>
                      <th scope="col">Last updated</th>
                      <th scope="col">Phone number</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="dataTable">
                    {loading ? (
                      <div className="loader_Section">
                        <Loader />
                      </div>
                    ) : filteredData.length === 0 ? (
                      <div className="No-data-Vector">
                        <img src="./assets/Gif/NoDeal-Data.gif" alt="No Data" />
                      </div>
                    ) : (
                      paginatedData.map((deal) => (
                        <tr key={deal.id}>
                          <td>{deal.id}</td>
                          <td>
                            {deal.firstName} {deal.lastName}
                          </td>
                          <td>{deal.vinNumber}</td>
                          <td>{deal.vehicle}</td>
                          <td>{deal.vehicleColor}</td>
                          <td>{formatDate(deal.updatedAt)}</td>
                          <td>{deal.phoneNumber}</td>
                          <td>
                            <div
                              className="Table_Actions"
                              onClick={playClickSound}
                            >
                              <a
                                href="/"
                                data-bs-toggle="modal"
                                data-bs-target="#WaitingModal"
                                onClick={() => {
                                  playClickSound();
                                  setSelectedDealId(deal.id);
                                }}
                              >
                                <MdRemoveRedEye className="MdRemoveRedEye" />
                              </a>
                              <a
                                href="/"
                                data-bs-toggle="modal"
                                data-bs-target="#shareLinkModal"
                              >
                                <HiShare
                                  className="HiShare"
                                  onClick={() => {
                                    playClickSound();
                                    setShareDealId(deal.id);
                                  }}
                                />
                              </a>
                              <a
                                href="/"
                                data-bs-toggle="modal"
                                data-bs-target="#Delete-Deal-Confirmation"
                                onClick={() => {
                                  playClickSound();
                                  setDealToDelete(deal.id);
                                }}
                              >
                                <RiDeleteBin2Fill className="RiDeleteBin2Fill" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                <div className="Table_foot justify-content-between">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(filteredData.length / rowsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      shape="rounded"
                    />
                  </Stack>

                  <div className="Foot_btn">
                    <MyButton
                      title="Add new deal"
                      data-bs-toggle="modal"
                      data-bs-target="#AddDealModal"
                      onModalOpen={() => {
                        setModalState(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal modalState={isModalOpen} />
      </section>

      {/*========== here is start shareLinkModal ========== */}

      <div
        className="modal"
        id="shareLinkModal"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <div className="d-flex align-items-center gap-2">
                <img
                  src="./Images/tools.png"
                  alt=""
                  style={{ width: "30px" }}
                />
                <h1 className="modal-title" id="exampleModalLabel">
                  Share Deal Link
                </h1>
              </div>
              <button
                type="button"
                className="btn-close"
                id="mailmodal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closemodal}
              ></button>
            </div>
            <div className="modal-body px-4">
              <InputFiled
                InputBoxClassname="half"
                InputType="email"
                InputName="EmailAddress"
                lableValue="Email Address"
                Inputplaceholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="modal-footer border-0 justify-content-center mt-2">
              <button onClick={sendEmail} className="MyBtn">
                Share Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ===== here is start WaitingModal ======= */}

      <div
        className="modal"
        id="WaitingModal"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop rounded-3 p-0">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <div className="d-flex align-items-center gap-2">
                <img
                  src="./Images/Clock.png"
                  alt=""
                  style={{ width: "30px" }}
                />
                <h1 className="modal-title" id="exampleModalLabel">
                  Waiting Period
                </h1>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
            </div>
            <div className="modal-body px-4">
              <SelectField
                required
                className={half}
                InputName="Waiting Period"
                Selectlabel="Waiting Period"
                onChange={handleSelectChange}
                options={[
                  { value: "10", label: "10 Minutes" },
                  { value: "20", label: "20 Minutes" },
                  { value: "30", label: "30 Minutes" },
                  { value: "40", label: "40 Minutes" },
                  { value: "50", label: "50 Minutes" },
                  { value: "60", label: "1 Hour" },
                ]}
              />
            </div>
            <div className="modal-footer border-0 justify-content-center my-3">
              <span onClick={handleClick}>
                <MyButton
                  title="Ok"
                  data-bs-toggle="modal"
                  data-bs-target="#DealVideoModal"
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start AddModal ========== */}

      <div
        className="modal"
        id="DealVideoModal"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop bg-transparent">
          <div className="modal-content border-0 bg-transparent">
            <div className="modal-body p-0">
              <div
                className={`background-video position-static ${
                  isMac ? "mac-specific" : ""
                }`}
                onClick={togglePlay}
              >
                <div>
                  <video
                    className="w-100 Double_Videos"
                    id="myVideo"
                    ref={videoRef}
                  ></video>
                  <img
                    src="https://menuverse.ampleteckdev.com/newversa-1-1.jpg"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center my-3">
              <span onClick={handleNavigate}>
                <MyButton
                  title="Continue"
                  className="Secondry_Btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start delete deal confirmation popup ========== */}

      <div
        className="modal"
        id="Delete-Deal-Confirmation"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals rounded-3">
          <div className="modal-content border-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={playClickSound}
            ></button>
            <div className="modal-body p-0 text-center">
              <>
                <img
                  className=""
                  src="/Images/caution_icon.png"
                  alt="Caution"
                />
                <h4>Are you sure you want to delete this deal?</h4>
              </>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                className="Secondry_Btn"
                title="No"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <button
                className="MyBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleDelete(dealToDelete)}
              >
                {" "}
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deals;
