import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import useClipboard from "react-use-clipboard";
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import SearchData from "./DashboardRightSidebarSearchData";
import FAQComponent from "./FAQComponent";
import AccordionData from "./Accordion_Questions_Answers.json";
import { FiMicOff, FiMic } from "react-icons/fi";
import { IoSend } from "react-icons/io5";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Peer from 'peerjs';
import { Modal, Button } from 'react-bootstrap';
import { red } from "@mui/material/colors";
import '@fortawesome/fontawesome-free/css/all.min.css';



const DashboardRightSidebar = ({ setIsLogin, isModalOpen, onSidebarLoad }) => {
  const recorderControls = useAudioRecorder();
  const [transcription, setTranscription] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isQuerying, setIsQuerying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const usertoken = localStorage.getItem("token");
  const chatBoxRef = useRef(null);
  const navigate = useNavigate();
  const [messageSent, setMessageSent] = useState(false);


  const addAudioElement = (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");

    setIsLoading(true);

    axios
      .post("https://api.menuverse.ai/transcribe2", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + usertoken,
        },
      })
      .then((response) => {
        setTranscription(response.data.text);
        setIsLoading(false);
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            localStorage.clear();
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error during transcription:", error);
        setIsLoading(false);
        // navigate("/login")
      });
  };

  const handleRecording = () => {
    if (isRecording) {
      startConverting();
      recorderControls.stopRecording();
    } else {
      startConverting();
      recorderControls.startRecording();
    }
    setIsRecording(!isRecording);
  };

  const handleMouseDown = () => {
    // Start recording when the button is pressed
    if (!isRecording) {
      startListening();
      recorderControls.startRecording();
      setIsRecording(true);
    }
  };

  const handleMouseUp = () => {
    // Stop recording when the button is released
    if (isRecording) {
      stopListening();
      recorderControls.stopRecording();
      setIsRecording(false);
    }
  };

  const handleInputChange = (e) => {
    setTranscription(e.target.value);
  };

  const [textToCopy, setTextToCopy] = useState();
  const [isCopied, setCopied] = useClipboard(textToCopy, {
    successDuration: 1000,
  });
  const [userQuestionsAndAnswers, setUserQuestionsAndAnswers] = useState([]);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [activeButton, setActiveButton] = useState("VSC");
  const [isListening, setIsListening] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [ActiveIndexAccordion, setActiveIndexAccordion] = useState();
  const [VideoLiveLink, setVideoLiveLink] = useState();

  const hanleInexXhnage = (index) => {
    setActiveIndexAccordion(index);
  };

  useEffect(() => {
    setFaqs(AccordionData);
  }, []);

  useEffect(() => {
    $("#ExpandButton").click(function () {
      if ($(this).text() === "Collapse") {
        $(this).text("Expand");
        $(this).prepend('<img src="/assets/Images/DashboardImages/expand-arrowsIcon.png" alt="">');
      } else {
        $(this).text("Collapse");
        $(this).prepend('<img src="/assets/Images/DashboardImages/expand-arrowsCollaps-Icon.png" alt="">');
      }
      $(".Dashboard-right-Sidebar").toggleClass("Expanded");
    });
  }, []);

  useEffect(() => {
    const inputField = document.getElementById("styled-placeholder-input");

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSend();
      }
    };

    inputField.addEventListener("keydown", handleKeyDown);

    return () => {
      inputField.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputValue, transcription]);

  const startListening = () => SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  const stopListening = () => {
    SpeechRecognition.stopListening();
    const correctedTranscript = autoCorrectTranscript(transcript);
    setTextToCopy(correctedTranscript);
    setCopied();
  };

  const startConverting = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
    setIsListening(!isListening);
  };

  const handleSend = () => {
    if (!inputValue.trim() && !transcription.trim()) {
      return;
    }

    if (isQuerying) {
      return;
    }

    setIsQuerying(true);
    onSend();
    const Dealid = parseInt(window.location.pathname.split("/")[2], 10);
    const data = {
      query: inputValue || transcription,
      dealId: Dealid.toString(),
      state: "Alabama",
    };

    // Add the new question to the state with a fetching status
    setUserQuestionsAndAnswers((prev) => [...prev, { question: data.query, answer: "", fetching: true }]);

    setInputValue("");
    setTranscription("");
    resetTranscript();

    fetch("https://versa.ampleteck.com/userQuery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": "4$4V7r^!7{Rz3}7[=)M{f#y!&5>rTq6eS[t&kx)Uv%9*R*72;X8R*tJ,>3MBc(eW}D==]5x@qMX?84gP?kZx;T8E42h)P3G!p4#7",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        const videoPath = data.video_path;
        setUserQuestionsAndAnswers((prev) => prev.map((item) => (item.question === data.query ? { ...item, answer: data.llm_output, fetching: false } : item)));
        setIsQuerying(false);
        setVideoLiveLink(videoPath);
      })
      .catch((error) => {
        console.error("Error:", error);
        setUserQuestionsAndAnswers((prev) => prev.map((item) => (item.question === data.query ? { ...item, fetching: false } : item)));
        setIsQuerying(false);
      });
  };

  const onSend = () => {
    setMessageSent((prev) => !prev);
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messageSent]);

  const autoCorrectTranscript = (text) => {
    let correctedText = text.split(" ").join(" ");

    correctedText = correctedText.replace(/versa|vursa|virsa|verssa/gi, "Versa");

    return correctedText;
  };

  if (!browserSupportsSpeechRecognition) {
    return <div>Browser does not support speech recognition.</div>;
  }


  return (
    <div className="col-lg-3 Dashboard-right-Sidebar">
      <div className="Main_Right_Sidebar">
        <div>
      {/* <h4>Your ID: {peerId}</h4> */}

          <div className="RightHeader">
            <img
              onLoad={() => {
                onSidebarLoad();
              }}
              src="/assets/Images/DashboardImages/AskVersaFrame.png"
              alt=""
            />
            <button id="ExpandButton">
              <img src="/assets/Images/DashboardImages/expand-arrowsIcon.png" alt="" />
              Expand
            </button>
          </div>
          <div className="RightSideBarBody">
            <div className="accordion" id="accordionParent">
              <div className="accordion-item ">
                <h2 className="accordion-header accordionParentButton">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseParent" aria-expanded="true" aria-controls="collapseParent">
                    Frequently Asked Questions
                  </button>
                </h2>
                <div id="collapseParent" className="accordion-collapse collapse">
                  <div className="accordion" id="accordionChild">
                    <FAQComponent faqs={faqs} activeButton={activeButton} setVideoURL={setVideoURL} onInexChange={hanleInexXhnage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* sidebar footer */}
        <div className="Right_SidebarFooter">
          <div className="Right-SideBar-Body-Bottom">
            <SearchData setActiveButton={setActiveButton} activeButton={activeButton} videoURL={videoURL} ActiveIndexAccordion={ActiveIndexAccordion} setActiveIndexAccordion={setActiveIndexAccordion} VideoLiveLink={VideoLiveLink} isModalOpen={isModalOpen} />
          </div>

          <div className="ChatBox_Api" ref={chatBoxRef}>
            {userQuestionsAndAnswers.map((item, index) => (
              <div key={index}>
                <div className="My_Qoustion">
                  <p>{item.question}</p>
                </div>
                <div className="Api_Response">{item.fetching ? <div className="New-chat-loader"></div> : <p>{item.answer}</p>}</div>
              </div>
            ))}
          </div>

          <div className="RightFooter">
            <input type="text" placeholder=" " style={{ width: "100%" }} id="styled-placeholder-input" value={isLoading ? "Loading..." : inputValue || transcription} onChange={handleInputChange} disabled={isLoading} />
            <div className="placeholder-wrapper">
              {isListening ? (
                <>
                  <span className="first-letter"> Recording..... </span>
                </>
              ) : (
                <>
                  <span className="first-letter">Ask </span> VERSA
                </>
              )}
            </div>
            <div className="Mike_And_Send_Btn">
              <button onClick={handleRecording} className={`Mic-Btn-For-Desktop ${isListening ? "active" : ""}`}>
                {isListening ? <FiMic /> : <FiMicOff />}
              </button>

              <button onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onTouchStart={handleMouseDown} onTouchEnd={handleMouseUp} className={` Mic-Btn-For-Mobile ${isListening ? "active" : ""}`}>
                {isListening ? <FiMic /> : <FiMicOff />}
              </button>
              <button className="MyBtn" onClick={handleSend}>
                <IoSend />
              </button>
            </div>
          </div>

          <div className="d-none">
            <AudioRecorder onRecordingComplete={addAudioElement} recorderControls={recorderControls} />
            <button onClick={handleRecording}>{isRecording ? "Stop Recording" : "Start Recording"}</button>
            <div>
              <h3>Transcription:</h3>
              <input type="text" value={transcription} onChange={handleInputChange} />
            </div>
          </div>


         
        </div>
      </div>
    </div>
  );
};

export default DashboardRightSidebar;
