import React, { useState, useEffect } from "react";

const FAQComponent = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    setActiveIndex(null);
  }, [props.activeButton]);

  const toggleAccordion = (index, videoURL) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      props.setVideoURL("");
      props.onInexChange(null);
    } else {
      setActiveIndex(index);
      props.setVideoURL(videoURL);
      props.onInexChange(index, videoURL);
    }
  };

  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };
  const playAudio = () => {
    play("/assets/Audios/clicksoundeffect.mp3");
  };

  return (
    <>
      <div className="accordion">
        {props.faqs.map((faqGroup, index) => {
          if (faqGroup.title === props.activeButton) {
            return (
              <div key={index}>
                {faqGroup.faqs.map((faq, faqIndex) => (
                  <div className="accordion-item" key={faqIndex} onClick={playAudio}>
                    <button className={`accordion-button ${faqIndex === activeIndex ? "" : "collapsed"}`} type="button" onClick={() => toggleAccordion(faqIndex, faq.videoURL)} aria-expanded={faqIndex === activeIndex ? "true" : "false"} aria-controls={`collapse${index}-${faqIndex}`}>
                      {faq.question}
                    </button>
                    <div id={`collapse${index}-${faqIndex}`} className={`accordion-collapse collapse ${faqIndex === activeIndex ? "show" : ""}`}>
                      <div className="accordion-body">
                        <p>{faq.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          } else {
            return null; // Render nothing if faqGroup does not match activeButton
          }
        })}
      </div>
    </>
  );
};

export default FAQComponent;
