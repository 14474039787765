import React, { useState, useEffect, useRef } from "react";
import "./CoinGame.css";
import MyButton from "../../CommonFolder/MyButton";

const CoinGame = (props) => {
  const [reward, setReward] = useState(null);
  const [buttons, setButtons] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [clickedIndices, setClickedIndices] = useState([]);
  const [gameWon, setGameWon] = useState(true);
  const [isnotplayed, setisnotplayed] = useState(true);
  const [scratchedCardIndex, setScratchedCardIndex] = useState(null);
  const [scratchPercentages, setScratchPercentages] = useState({});
  const canvasRefs = useRef([]);
  const isScratching = useRef(false);
  const winningVideo = useRef(null);
  const scratchingAudio = useRef(new Audio("/assets/Audios/scratching.mp3"));
  const magicAudio = useRef(new Audio("/assets/Audios/best.mp3"));
  const bananaAudio = useRef(new Audio("/assets/Audios/magic.mp3"));
  const [hasSetGameWonFalse, setHasSetGameWonFalse] = useState(false);
  const [hasshowmsg, sethasshowmsg] = useState(false);
  const [showGif, setShowGif] = useState(false);

  const buttonOptions = [
    {
      value: 1000,
      imgSrc: "/assets/Images/GameImage/scratch_card-1000.png",
      alt: "1000 coins",
    },
    {
      value: 10,
      imgSrc: "/assets/Images/GameImage/scratch_card-10.png",
      alt: "0 coins",
    },
    {
      value: 10,
      imgSrc: "/assets/Images/GameImage/scratch_card-10.png",
      alt: "0 coins",
    },
    {
      value: 10,
      imgSrc: "/assets/Images/GameImage/scratch_card-10.png",
      alt: "0 coins",
    },
    {
      value: 10,
      imgSrc: "/assets/Images/GameImage/scratch_card-10.png",
      alt: "0 coins",
    },
  ];

  const shuffleButtons = () => {
    const shuffled = [...buttonOptions].sort(() => Math.random() - 0.5);
    setButtons(shuffled);
  };

  const resetCanvas = () => {
    canvasRefs.current.forEach((canvas) => {
      if (canvas) {
        const ctx = canvas.getContext("2d");
        ctx.globalCompositeOperation = "source-over";
        ctx.fillStyle = "#e6ae55";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
    });
  };

  const fireworkplay = () => {
    const video = winningVideo.current;
    if (video) {
      if (!video.paused) {
        video.pause();
      }
      video.currentTime = 0;
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    }
    setHasSetGameWonFalse(false);
    setisnotplayed(true);
    sethasshowmsg(false);
    setShowGif(false);
    props.handleContinueClickContinueGame();
  };

  useEffect(() => {
    // Preload audio files
    // scratchingAudio.current.load();
    // magicAudio.current.load();
    // bananaAudio.current.load();
    shuffleButtons();
  }, []);

  useEffect(() => {
    resetCanvas();
  }, [buttons]);

  const handleMouseDown = (index) => {
    // scratchingAudio.current
    //   .play()
    //   .catch((error) =>
    //     console.error("Error playing scratching audio:", error)
    //   );
    // console.log("scratchingAudio audio play");
    // if (scratchedCardIndex === null || scratchedCardIndex === index) {
    //   isScratching.current = true;
    //   setScratchedCardIndex(index);
    // }
  };

  const handleMouseUp = () => {
    // // console.log("6");
    // isScratching.current = false;
    // scratchingAudio.current.pause();
    // scratchingAudio.current.currentTime = 0;
  };

  const handleMouseMove = (e, index) => {
    // console.log("7");
    if (!isScratching.current || index !== scratchedCardIndex) return;
    sethasshowmsg(true);

    const canvas = canvasRefs.current[index];
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    ctx.globalCompositeOperation = "destination-out";
    ctx.beginPath();
    ctx.arc(x, y, 20, 0, Math.PI * 2, false);
    ctx.fill();

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const totalPixels = imageData.data.length / 4;
    let scratchedPixels = 0;

    for (let i = 0; i < totalPixels; i++) {
      if (imageData.data[i * 4 + 3] === 0) {
        scratchedPixels++;
      }
    }

    const scratchPercentage = (scratchedPixels / totalPixels) * 100;
    setScratchPercentages((prev) => ({ ...prev, [index]: scratchPercentage }));

    if (scratchPercentage >= 60) {
      setisnotplayed(false);
      if (!hasSetGameWonFalse) {
        setGameWon(false);
        setHasSetGameWonFalse(true);
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setScratchPercentages((prev) => ({ ...prev, [index]: 100 }));
    }
  };

  const handleSelection = (value, index) => {
    if (clickCount < 1 && !clickedIndices.includes(index)) {
      const scratchPercentage = scratchPercentages[index] || 0;

      if (scratchPercentage >= 40) {
        if (buttons[index].value === 1000) {
          magicAudio.current
            .play()
            .catch((error) =>
              console.error("Error playing magic audio:", error)
            );
        } else {
          bananaAudio.current
            .play()
            .catch((error) =>
              console.error("Error playing banana audio:", error)
            );
        }

        if (hasshowmsg) {
          setReward(`You clicked the button with value  ${value}`);
          setTimeout(() => {
            setReward("");
          }, 3000);
        }
      }

      if (value === 1000) {
        setGameWon(true);
        setShowGif(true);
        setisnotplayed(false);
      } else if (value === 10) {
        if (hasshowmsg) {
          setReward(`You clicked the button with value  ${value}`);
          setTimeout(() => {
            setReward("");
          }, 3000);
        }
      }

      setClickCount(clickCount + 1);
      setClickedIndices((prevIndices) => [...prevIndices, index]);
    }
  };

  useEffect(() => {
    const handleModalShow = () => {
      setReward(null);
      shuffleButtons();
      setClickCount(0);
      setClickedIndices([]);
      setGameWon(true);
      setScratchedCardIndex(null);
      setScratchPercentages({});
      setTimeout(resetCanvas, 0);
    };

    const modalElement = document.getElementById("GameThree");
    modalElement.addEventListener("show.bs.modal", handleModalShow);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleModalShow);
    };
  }, []);

  const handleNewButtonClick = () => {
    setReward(null);
    shuffleButtons();
    setClickCount(0);
    setClickedIndices([]);
    setScratchedCardIndex(null);
    setScratchPercentages({});
    setTimeout(resetCanvas, 0);
    setisnotplayed(true);
    sethasshowmsg(false);
    setGameWon(true);
  };

  return (
    <>
      <div
        className="modal"
        id="GameThree"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop">
          <div className="modal-content CoinGame_Modal position-relative">
            {showGif && (
              <img
                className="coin-falling-gif"
                src="../assets/Gif/coin.gif"
                alt=""
              />
            )}

            {reward && <div className="reward-message">{reward}</div>}

            <div className="modal-header">
              <span className="Coin_gameplay_Button">
                <img
                  src="/assets/Images/GameImage/playing_5187999Dice.png"
                  alt=""
                />
                Play to Win
              </span>
            </div>
            <div className="Modal-body">
              <div className="game-container">
                <div className="options">
                  {buttons.slice(0, 4).map((button, index) => (
                    <div
                      key={index}
                      className={`coin-button ${
                        clickedIndices.includes(index) ? "scraped" : ""
                      }`}
                      onClick={() => handleSelection(button.value, index)}
                    >
                      <canvas
                        ref={(el) => (canvasRefs.current[index] = el)}
                        width="120"
                        height="120"
                        onMouseDown={() => handleMouseDown(index)}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp} // To handle case when mouse leaves the canvas area
                        onMouseMove={(e) => handleMouseMove(e, index)}
                        className="scratch-canvas"
                      ></canvas>
                      <img
                        src={button.imgSrc}
                        alt={button.alt}
                        className="coin-image"
                        draggable="false"
                      />
                    </div>
                  ))}
                </div>
                <div className="new-button">
                  <button onClick={handleNewButtonClick} disabled={gameWon}>
                    NEW
                  </button>

                  <span onClick={fireworkplay}>
                    <MyButton
                      title="Continue"
                      className="Secondry_Btn"
                      data-bs-toggle="modal"
                      data-bs-target="#JackpotGameThree"
                      disabled={isnotplayed}
                    ></MyButton>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========congrats popup here===========  */}

      <div
        className="modal"
        id="JackpotGameThree"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop GameThree">
          <div className="modal-content border-0 overflow-hidden">
            <video
              className="Winning-video-play"
              ref={winningVideo}
              src="/assets/Videos/-a246-4365-bfef-3eca5dc379ac.webm"
              type="video/webm"
              preload="none"
            ></video>
            <div className="modal-body" style={{ zIndex: 2 }}>
              <h1>Congrats! You've won a discount coupon</h1>
              <img
                className="w-100"
                src="../assets/Gif/dbfafc3ae048af27715b1a4f42ee9aa5.gif"
                alt=""
              />
              <p>You've won an offer</p>
              <h3>BUY 2 GET 1</h3>
              <p>free on ancillary product</p>
            </div>

            <div
              className="modal-footer border-0 justify-content-center mt-2"
              style={{ zIndex: 2 }}
            >
              <span onClick={props.handleContinueClickThree} className="m-0">
                <MyButton
                  title="Continue"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoinGame;
