import React, { useState, useEffect, Suspense, useRef } from "react";
import QuestionnaireHeader from "./QuestiononeData/QuestionnaireHeader";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import "./Questionnaire.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loader from "../CommonFolder/Loader";
import { useVideo } from "../../VideoContext";
const QuestionnaireBodyData = React.lazy(() =>
  import("./QuestiononeData/QuestionnaireBodyData")
);
const VersaAiBot = React.lazy(() => import("./QuestiononeData/VersaAiBot"));
const QuestionnaireFooter = React.lazy(() =>
  import("./QuestiononeData/QuestionnaireFooter")
);

const Questionone = ({ setIsLogin }) => {
  const { playPreloadedQuestionVideos } = useVideo();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showReferAndEarn, setShowReferAndEarn] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [Gamevideo, setGamevideo] = useState("");
  const [Gamevideocontinue, setGamevideocontinue] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { firstName } = location.state || {};
  const [handleContinueActive, sethandleContinueActive] = useState(false);
  const usertoken = localStorage.getItem("token");
  const childRef = useRef(null);
  const [checkboxContinue, setcheckboxContinue] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(5); // New state for selected options
  const [offValues, setOffValues] = useState([]); // <-- Lift offValues to parent
  const [offValuesId, setOffValuesId] = useState([]); // <-- Lift offValues to parent

  const handleGamevideo = (e) => {
    setGamevideocontinue(false);
    if (e === true) {
      setGamevideo("1");
    } else {
      setGamevideo("video url");
    }
  };

  const CongratsContinueGame = () => {
    setGamevideocontinue(true);
  };

  const handleContinue = () => {
    if (isLastQuestion && showReferAndEarn) {
      navigate(`/presentation/${id}`);
    } else if (isLastQuestion && !showReferAndEarn) {
      if (
        handleContinueActive == true &&
        currentQuestion.questionType == "checkbox"
      ) {
        // alert("answer submitting2");
        // alert("currentQuestionIndex " + currentQuestionIndex);
        // alert("selectedOptions " + selectedOptions);
        // setCurrentQuestionIndex(currentQuestionIndex + 1);
        // sethandleContinueActive(false);
        // setcheckboxContinue(true);
        const selectedOptionId = selectedOptions;
        // const selectedOptionId = 4;
        if (selectedOptionId) {
          // alert("answer submitting");
          // alert("selectedOptionId " + selectedOptionId);
          handlePostAnswer(selectedOptionId); // Trigger the API call here
        }
      }
      setShowReferAndEarn(true);
    } else if (currentQuestion.questionType == "checkbox") {
      // alert("answer submitting1");
      if (handleContinueActive == true) {
        // alert("answer submitting2");
        // alert("currentQuestionIndex " + currentQuestionIndex);
        // alert("selectedOptions " + selectedOptions);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        sethandleContinueActive(false);
        setcheckboxContinue(true);
        const selectedOptionId = selectedOptions;
        // const selectedOptionId = 4;
        if (selectedOptionId) {
          // alert("answer submitting");
          // alert("selectedOptionId " + selectedOptionId);
          handlePostAnswer(selectedOptionId); // Trigger the API call here
        }
      } else {
        setCurrentQuestionIndex(currentQuestionIndex);
      }
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }

    if (childRef.current) {
      childRef.current.childMethod();
    }
  };

  const showPreviousLink = () => {
    if (showReferAndEarn) {
      setShowReferAndEarn(false);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const selectedValueForWaiting = localStorage.getItem(
    "selectedValueForWaiting"
  );

  useEffect(() => {
    fetch(
      `https://api.menuverse.ai/questions/show/${id}/${selectedValueForWaiting}`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            localStorage.clear();
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        const coupons = responseData.data[currentQuestionIndex]?.coupons || [];
        const fetchedOffValues = coupons.map((coupon) => coupon.off);
        const fetchedcouponsId = coupons.map((coupon) => coupon);
        setOffValues(fetchedOffValues);
        setOffValuesId(fetchedcouponsId);
        setData(responseData.data);
        const i = 1;
        for (const questionData of responseData.data) {
          if (i <= 3) {
            playPreloadedQuestionVideos(
              questionData.video,
              "videoQuestion" + questionData.id
            );
          }
          i++;
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [id]);

  const handlePostAnswer = async (selectedOptionId) => {
    const Dealid = parseInt(window.location.pathname.split("/")[2], 10);

    try {
      if (selectedOptionId) {
        // alert("received optionID " + selectedOptionId);
        const response = await fetch(
          "https://api.menuverse.ai/answers/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + usertoken,
            },
            body: JSON.stringify({
              question_id: data[currentQuestionIndex].id,
              deal_id: Dealid,
              selected_option_id: selectedOptionId,
              answer_value: selectedOptionId,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to post answer");
        }

        const responseData = await response.json();
      }
    } catch (error) {}
  };
  if (checkboxContinue) {
    handlePostAnswer();
  }

  const currentQuestion = data[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === data.length - 1;

  const renderQuestionnaireBody = () => {
    if (!currentQuestion) {
      return null;
    }

    const { questionType, buttonTitles, questionOptions } = currentQuestion;

    switch (questionType) {
      case "slider":
        return (
          <Suspense fallback={<Loader />}>
            <QuestionnaireBodyData
              RangeSet={true}
              DoorDefaultValue={questionOptions.map((option) => option.default)}
              ref={childRef}
            />
          </Suspense>
        );
      case "number":
        return (
          <Suspense fallback={<Loader />}>
            <QuestionnaireBodyData
              PlusMinus={true}
              PlusMinusTitle={questionOptions}
              PlusMinusDefaultValue={questionOptions.map(
                (option) => option.default_value
              )}
              questionId={currentQuestion.id}
            />
          </Suspense>
        );
      case "checkbox":
        return (
          <Suspense fallback={<Loader />}>
            <QuestionnaireBodyData
              Determining={true}
              handlePostAnswer={handlePostAnswer}
              buttonTitles={buttonTitles}
              titleofoptions={questionOptions}
              questionId={currentQuestion.id}
              sethandleContinueActive={sethandleContinueActive}
              handleSelectedOptions={(e) => {
                setSelectedOptions(e);
              }}
            />
          </Suspense>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <main className="Questionnaire QuestionnaireOne">
        <div className="container">
          <QuestionnaireHeader questionNumber={currentQuestionIndex + 1} />
          <div className="QuestionnaireBody container">
            <div className="row justify-content-center pe-3">
              {!showReferAndEarn ? (
                <div className="col-lg-9 col-md-12">
                  {/* <div>
                    <span>{`Question ${currentQuestionIndex + 1}`}</span>
                  </div> */}
                  <div>
                    {data.map((question, index) => (
                      <div key={index}>
                        {index === currentQuestionIndex && (
                          <h4>{question.title}</h4>
                        )}
                      </div>
                    ))}
                  </div>
                  {renderQuestionnaireBody()}
                </div>
              ) : (
                <div className="col-lg-9 col-md-12">
                  <div className="ReferAndEarn">
                    <h4>Refer someone to earn a discount you can use today.</h4>
                    <div className="row">
                      <InputFiled
                        InputBoxClassname="col-md-6 mb-3 DisabledInputs"
                        Inputplaceholder="Stephen"
                        InputType="text"
                        InputName="FirstName"
                        lableValue="First name"
                      />

                      <InputFiled
                        InputBoxClassname="col-md-6 mb-3 DisabledInputs"
                        Inputplaceholder="Jones"
                        InputType="text"
                        InputName="FirstName"
                        lableValue="Last Name"
                      />

                      <InputFiled
                        InputBoxClassname="col-md-6 mb-3 DisabledInputs"
                        Inputplaceholder="stephenj@gmail.com"
                        InputType="email"
                        InputName="FirstName"
                        lableValue="Email address"
                      />

                      <InputFiled
                        InputBoxClassname="col-md-6 mb-3 DisabledInputs"
                        Inputplaceholder="(925) 676-5490"
                        InputType="tel"
                        InputName="FirstName"
                        lableValue="Phone number"
                      />
                    </div>
                  </div>
                </div>
              )}
              <Suspense fallback={<Loader />}>
                <VersaAiBot
                  Gamevideo={Gamevideo}
                  currentQuestionIndex={currentQuestionIndex}
                  data={data}
                  isLastQuestion={isLastQuestion}
                  Gamevideocontinue={Gamevideocontinue}
                  setGamevideocontinue={setGamevideocontinue}
                  showReferAndEarn={showReferAndEarn}
                />
              </Suspense>
            </div>
          </div>
          <Suspense fallback={<Loader />}>
            <QuestionnaireFooter
              previousLink={showPreviousLink}
              onNext={handleContinue}
              onGamePopup={handleGamevideo}
              showPrevious={currentQuestionIndex > 0 || showReferAndEarn}
              currentQuestionIndex={currentQuestionIndex}
              continueModel={`/Questionone/${id}`}
              isLastQuestion={isLastQuestion}
              showReferAndEarn={showReferAndEarn}
              CongratsContinueGame={CongratsContinueGame}
              setGamevideocontinue={setGamevideocontinue}
              offValues={offValues}
              offValuesId={offValuesId}
            />
          </Suspense>
        </div>
      </main>
    </>
  );
};

export default Questionone;
