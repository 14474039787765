import React, { useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import InputFiled from "../FormStructure/InputField";
import { useAudio } from "../../../AudioContext";
import { FaFilter } from "react-icons/fa";
import { HiShare } from "react-icons/hi";

const TableHeader = ({ title, showFilters, showSareLink, Monthlyfinance, onSearch, onFilterChange }) => {
  const searchInputRef = useRef(null);
  const [selectedDealType, setSelectedDealType] = useState("");
  const [selectedClass, setSelectedClass] = useState("");

  // const play = (audioPath) => {
  //   const audio = new Audio(audioPath);
  //   audio.play().catch((error) => {
  //     console.error("Audio playback failed:", error);
  //   });
  // };
  const { playClickSound } = useAudio();
  // const playAudio = () => {
  //   alert("I am clickedddd");
  //   play("/assets/Audios/clicksoundeffect.mp3");
  // };

  const handleSearchChange = () => {
    if (onSearch && searchInputRef.current) {
      onSearch(searchInputRef.current.value);
    }
  };

  // const handleDealTypeChange = (e) => {
  //   const value = e.target.value || "AllDeal";
  //   setSelectedDealType(value);
  // };

  // const handleClassChange = (e) => {
  //   const value = e.target.value || "AllClass";
  //   setSelectedClass(value);
  // };

  const handleDealTypeChange = (e) => {
    const value = e.target.value;
    setSelectedDealType((prev) => (prev === value ? "" : value));
  };

  const handleClassChange = (e) => {
    const value = e.target.value;
    setSelectedClass((prev) => (prev === value ? "" : value));
  };

  const SearchData = () => {
    playClickSound();
    // play("/assets/Audios/clicksoundeffect.mp3");

    const dealType = selectedDealType || "AllDeal";
    const classType = selectedClass || "AllClass";

    if (onFilterChange) {
      onFilterChange({ dealType, classType });
    }
  };

  return (
    <>
      <div className="table_header">
        <h3>{title}</h3>
        {showFilters && (
          <div className="Table_filters">
            <div className="dropdown d-grid">
              <a href="/" className="Filter" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={playClickSound}>
                <FaFilter />

                <span>Filter</span>
                <FaAngleDown />
              </a>

              <div className="dropdown-menu">
                <div className="border-bottom p-3">
                  <h5>Deal Type</h5>
                  <div className="d-flex align-items-center gap-2">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="AllDeal" name="DealType" onClick={playClickSound} checked={selectedDealType === "AllDeal"} onChange={handleDealTypeChange} />
                      <label className="form-check-label">All</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Cash" name="DealType" onClick={playClickSound} checked={selectedDealType === "Cash"} onChange={handleDealTypeChange} />
                      <label className="form-check-label">Cash</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Finance" name="DealType" onClick={playClickSound} checked={selectedDealType === "Finance"} onChange={handleDealTypeChange} />
                      <label className="form-check-label">Finance</label>
                    </div>
                  </div>
                </div>

                <div className="border-bottom p-3 mb-3">
                  <h5>Class</h5>
                  <div className="d-flex align-items-center gap-2 flex-wrap">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="AllClass" name="ClassType" onClick={playClickSound} checked={selectedClass === "AllClass"} onChange={handleClassChange} />
                      <label className="form-check-label">All</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="New" name="ClassType" onClick={playClickSound} checked={selectedClass === "New"} onChange={handleClassChange} />
                      <label className="form-check-label">New</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Used" name="ClassType" onClick={playClickSound} checked={selectedClass === "Used"} onChange={handleClassChange} />
                      <label className="form-check-label">Used</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Certified Pre-Owned" name="ClassType" onClick={playClickSound} checked={selectedClass === "Certified Pre-Owned"} onChange={handleClassChange} />
                      <label className="form-check-label">Certified Pre-Owned</label>
                    </div>
                  </div>
                </div>
                <div className="filter_earch_btn mb-2">
                  <button type="button" className="" onClick={SearchData}>
                    SEARCH
                  </button>
                </div>
              </div>
            </div>

            <div className="Search">
              <input type="text" id="search" placeholder="Search Deal Here.." ref={searchInputRef} onChange={handleSearchChange} />
              {/* <a>
                <img src="./Images/search-icon.png" alt="" />
              </a> */}
            </div>
          </div>
        )}
        {showSareLink && (
          <div className="ShareLink">
            <a href="/" data-bs-toggle="modal" data-bs-target="#shareLinkModal" onClick={playClickSound}>
              <HiShare className="HiShareInHeader" />

              <span>Share a Link</span>
            </a>
          </div>
        )}
        {Monthlyfinance && (
          <div className="Monthly_Finance_text">
            <p className="m-0">Monthly finance payment estimate</p>
          </div>
        )}
      </div>
      {/*========== here is start shareLinkModal ========== */}

      {/* <div className="modal" id="shareLinkModal" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog Waiting_pop">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <div className="d-flex align-items-center gap-2">
                <img src="./Images/tools.png" alt="" style={{ width: "30px" }} />
                <h1 className="modal-title" id="exampleModalLabel">
                  Share Deal Link
                </h1>
              </div>
              <button type="button" className="btn-close" id="mailmodal" data-bs-dismiss="modal" aria-label="Close" onClick={playClickSound}></button>
            </div>
            <div className="modal-body px-4">
              <InputFiled InputBoxClassname="half" InputType="email" InputName="EmailAddress" lableValue="Email Address" Inputplaceholder="name@example.com" onChange={(e) => setEmail(e.target.value)} value={email} />
            </div>
            <div className="modal-footer border-0 justify-content-center mt-2">
              <button onClick={sendEmail} className="MyBtn">
                Share Now
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TableHeader;
