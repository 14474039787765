import React, { useState, useEffect, useRef } from "react";
import MyButton from "../../CommonFolder/MyButton";
// import Seviceoptions from "./DashboardCenterBody/Serviceoptions";
import SelectField from "../../CommonFolder/FormStructure/SelectField";

const DashboardModals = ({
  productData,
  modalId,
  productDetail,
  interestData,
  termData,
  second_termData,
  onSaveMenu,
}) => {
  const [productUpdatedData, setproductUpdatedData] = useState([]);

  // useEffect(() => {
  //   if (productData.length > 0) {
  // console.log("modalside productdata, :", productData);

  //     setproductUpdatedData(productData);
  //     console.log(productData);
  //   }
  // }, [productData]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const Dealid = parseInt(window.location.pathname.split("/")[2], 10);
  const usertoken = localStorage.getItem("token");
  const divRef = useRef(null);

  const initialReviews = [
    { category: "Ease of use", rating: 4 },
    { category: "Performance", rating: 5 },
    { category: "Functionality", rating: 2 },
  ];

  const [reviews, setReviews] = useState(initialReviews);

  let easeOfUseRating = 0;
  let performanceRating = 0;
  let functionalityRating = 0;

  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };
  const playAudio = () => {
    play("/assets/Audios/clicksoundeffect.mp3");
  };

  const [productTermsData, setProductTermsData] = useState([]);
  const [productMilesData, setProductMilesData] = useState([]);
  const [productDeductiblesData, setProductDeductiblesData] = useState([]);
  const [serviceIntervalData, setServiceIntervalData] = useState([]);
  const [tireRotationData, setTireRotationData] = useState([]);
  const [productPrice, setProductPrice] = useState(0);
  const menuIdRef = useRef(null);
  const productRef = useRef(null);
  const mileRef = useRef(null);
  const termRef = useRef(null);
  const deductibleRef = useRef(null);
  const tireRotationRef = useRef(null);
  const serviceIntervalRef = useRef(null);
  const handleProductChange = async (e) => {
    const productId = e.target.value;
    getTerms(productId);
  };

  const getTerms = async (productId) => {
    const response = await fetch(
      "https://api.menuverse.ai/menu/getProductTerms/" + productId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (response.status == 200) {
      const responseJson = await response.json();

      const products = responseJson.data;

      const productTermOptions = products.map((productTerm) => {
        return {
          value: productTerm.months,
          label: productTerm.months + " Months",
        };
      });

      productTermOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductTermsData(productTermOptions);
      // console.log(productOptions);
      getPrice();

      // mileRef.current.value = "Select";
      // termRef.current.value = "Select";
      // deductibleRef.current.value = "Select";
    }
  };

  const getMiles = async () => {
    const milesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductMiles/" +
        productRef.current.value +
        "/" +
        termRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (milesResponse.status == 200) {
      const milesResponseJson = await milesResponse.json();

      const productsMiles = milesResponseJson.data;

      const productMileOptions = productsMiles.map((productMile) => {
        return {
          value: productMile.miles,
          label: productMile.miles,
        };
      });

      productMileOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductMilesData(productMileOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getMilesWithParams = async (id, term) => {
    const milesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductMiles/" + id + "/" + term,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (milesResponse.status == 200) {
      const milesResponseJson = await milesResponse.json();

      const productsMiles = milesResponseJson.data;

      const productMileOptions = productsMiles.map((productMile) => {
        return {
          value: productMile.miles,
          label: productMile.miles,
        };
      });

      productMileOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductMilesData(productMileOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };

  const getServiceIntervals = async () => {
    const serviceResponse = await fetch(
      "https://api.menuverse.ai/menu/getServiceIntervals/" +
        productRef.current.value +
        "/" +
        termRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (serviceResponse.status == 200) {
      const serviceResponseJson = await serviceResponse.json();

      const servicIntervals = serviceResponseJson.data;

      const servicIntervalOptions = servicIntervals.map((servicInterval) => {
        return {
          value: servicInterval.service_interval,
          label: servicInterval.service_interval,
        };
      });

      servicIntervalOptions.unshift({
        value: "",
        label: "Select",
      });

      setServiceIntervalData(servicIntervalOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };

  const getServiceIntervalsWithParams = async (id, term) => {
    const serviceResponse = await fetch(
      "https://api.menuverse.ai/menu/getServiceIntervals/" + id + "/" + term,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (serviceResponse.status == 200) {
      const serviceResponseJson = await serviceResponse.json();

      const servicIntervals = serviceResponseJson.data;

      const servicIntervalOptions = servicIntervals.map((servicInterval) => {
        return {
          value: servicInterval.service_interval,
          label: servicInterval.service_interval,
        };
      });

      servicIntervalOptions.unshift({
        value: "",
        label: "Select",
      });

      setServiceIntervalData(servicIntervalOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };
  const getTireRotations = async () => {
    const tireRotationResponse = await fetch(
      "https://api.menuverse.ai/menu/getTireRotations/" +
        productRef.current.value +
        "/" +
        termRef.current.value +
        "/" +
        serviceIntervalRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (tireRotationResponse.status == 200) {
      const tireRotationJson = await tireRotationResponse.json();

      const tireRotations = tireRotationJson.data;

      const tireRoattionOptions = tireRotations.map((tireRotation) => {
        return {
          value: tireRotation.tire_rotations,
          label: tireRotation.tire_rotations,
        };
      });

      tireRoattionOptions.unshift({
        value: "",
        label: "Select",
      });

      setTireRotationData(tireRoattionOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getTireRotationsWithParams = async (id, months, intervals) => {
    const tireRotationResponse = await fetch(
      "https://api.menuverse.ai/menu/getTireRotations/" +
        id +
        "/" +
        months +
        "/" +
        intervals,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (tireRotationResponse.status == 200) {
      const tireRotationJson = await tireRotationResponse.json();

      const tireRotations = tireRotationJson.data;

      const tireRoattionOptions = tireRotations.map((tireRotation) => {
        return {
          value: tireRotation.tire_rotations,
          label: tireRotation.tire_rotations,
        };
      });

      tireRoattionOptions.unshift({
        value: "",
        label: "Select",
      });

      setTireRotationData(tireRoattionOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };

  const getDeductible = async () => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" +
        productRef.current.value +
        "/" +
        termRef.current.value +
        "/" +
        mileRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getDeductibleWithParams = async (id, term, miles) => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" +
        id +
        "/" +
        term +
        "/" +
        miles,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };
  const getDeductibleTwo = async () => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" +
        productRef.current.value +
        "/" +
        termRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getDeductibleWithTwoParams = async (id, term) => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" + id + "/" + term,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };
  useEffect(() => {
    if (productDetail?.id) {
      getTerms(productDetail.id);
      getMilesWithParams(productDetail.id, productDetail.months);
      getDeductibleWithParams(
        productDetail.id,
        productDetail.months,
        productDetail.miles
      );

      getServiceIntervalsWithParams(productDetail.id, productDetail.months);
      getTireRotationsWithParams(
        productDetail.id,
        productDetail.months,
        productDetail.service_interval
      );

      if (
        productDetail.category_type == "TWL" ||
        productDetail.category_type == "PDR"
      ) {
        getDeductibleWithTwoParams(productDetail.id, productDetail.months);
      }
    }
  }, [productData, modalId, productDetail]);

  const saveMenu = async () => {
    const data = {
      id: menuIdRef.current.value,
      product_id: productRef.current.value,
      miles: mileRef?.current?.value,
      months: termRef?.current?.value,
      deductible: deductibleRef?.current?.value,
      service_interval: serviceIntervalRef?.current?.value,
      tire_rotations: tireRotationRef?.current?.value,
      interest: interestData,
      term: termData,
      second_term: second_termData,
    };
    const menuResponse = await fetch("https://api.menuverse.ai/menu/saveMenu", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
      body: JSON.stringify(data),
    });

    if (menuResponse.status) {
      const menuResponseJson = await menuResponse.json();

      const menuData = menuResponseJson.data;
      onSaveMenu();
    }
  };

  const handleProductSubmit = () => {
    // alert("clicked");
    saveMenu();
  };

  const handleOtherSelectChanges = () => {
    getPrice();
  };

  const handleTermChanges = async () => {
    getMiles();
    getServiceIntervals();
    getDeductibleTwo();
  };

  const handleServiceIntervalChange = () => {
    getTireRotations();
  };

  const handleMileChanges = async () => {
    getDeductible();
  };

  const getPrice = async () => {
    const data = {
      product_id: productRef?.current?.value,
      miles: mileRef?.current?.value,
      months: termRef?.current?.value,
      deductible: deductibleRef?.current?.value,
      service_interval: serviceIntervalRef?.current?.value,
      tire_rotations: tireRotationRef?.current?.value,
    };

    const priceResponse = await fetch(
      "https://api.menuverse.ai/menu/getPrice",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(data),
      }
    );

    if (priceResponse.status == 200) {
      const responseJson = await priceResponse.json();
      const price = responseJson.data;

      setProductPrice(price.sell_price);

      console.log("Price", price);
    } else {
      setProductPrice(0);
    }
  };

  useEffect(() => {}, []);

  const handleStarClick = (index, starIndex) => {
    const updatedReviews = reviews.map((review, reviewIndex) =>
      reviewIndex === index ? { ...review, rating: starIndex + 1 } : review
    );
    setReviews(updatedReviews);
  };

  const saveRatings = () => {
    reviews.forEach((review) => {
      if (review.category === "Ease of use") {
        easeOfUseRating = review.rating;
      } else if (review.category === "Performance") {
        performanceRating = review.rating;
      } else if (review.category === "Functionality") {
        functionalityRating = review.rating;
      }
    });
  };

  // const videoWidth = () => {
  //   setIsFullWidth(!isFullWidth);
  // };

  return (
    <>
      {/*========== here is start NotAvailable ========== */}

      <div
        className="modal"
        id="NotAvailable"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={playAudio}
            ></button>
            <div className="modal-body p-0 text-center ">
              <img className="" src="/Images/caution_icon.png" alt="Success" />
              <h4>Not Available for Financing</h4>
              <p>
                This product is not available for financing , please ask your
                finance manager on how to purchase it.
              </p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                title="ok"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start DiscountCoupons ========== */}

      <div
        className="modal"
        id="DiscountCoupons"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals DiscountCoupons">
          <div className="modal-content border-0 ">
            <div className="modal-header p-0 justify-content-center border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <h4>Discount Coupons</h4>
            </div>
            <div className="modal-body p-0 text-center ">
              <p>Only one can be applied at a time</p>
              <ul className="list-group DiscountCouponsOptions">
                <li className="list-group-item">
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="DiscountCoupons"
                        className="form-check-input"
                      />
                      <span> 25% (GAP25APPI)</span>
                    </label>
                  </div>
                  <div className="Discount_Saved">
                    <h6>$230 Saved</h6>
                  </div>
                </li>
                <li className="list-group-item">
                  <div>
                    <input
                      type="radio"
                      name="DiscountCoupons"
                      className="form-check-input"
                    />
                    <span>Flat $250 off</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div>
                    <input
                      type="radio"
                      name="DiscountCoupons"
                      className="form-check-input"
                    />
                    <span>Buy 2 and get 1 Free</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div>
                    <input
                      type="radio"
                      name="DiscountCoupons"
                      className="form-check-input"
                    />
                    <span>Other Coupon</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                title="Apply Coupon"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Reviewsmodal ========== */}

      <div
        className="modal"
        id="Reviewsmodal"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals Reviewsmodal">
          <div className="modal-content p-4 border-0">
            <div className="modal-header p-0 justify-content-start">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playAudio}
              ></button>
              <img
                src="../assets/Images/DashboardImages/BigBlackStar.png"
                alt=""
              />
              <h4>Reviews</h4>
            </div>
            <div className="modal-body p-0 text-center">
              {reviews.map((review, index) => (
                <div className="Reviews_Content_And_Stars" key={index}>
                  <span>{review.category}</span>
                  <div className="stars">
                    {[...Array(5)].map((_, starIndex) => (
                      <span
                        key={starIndex}
                        onClick={() => handleStarClick(index, starIndex)}
                      >
                        <img
                          className="clickTarget"
                          src={
                            starIndex < review.rating
                              ? "../assets/Images/DashboardImages/Fullshare.png"
                              : "../assets/Images/DashboardImages/Emptystar.png"
                          }
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={playAudio}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              ))}
              <div className="Teaxtarea mt-3 mb-4">
                <textarea
                  placeholder="Write about your experience with us..."
                  rows="4"
                ></textarea>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                className="w-100 MyBtn"
                title="Share Now"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={saveRatings}
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Edit_Product ========== */}

      <div
        className="modal"
        id={modalId}
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals p-0">
          <div className="modal-content p-4 border-0 Edit_Product">
            <div className="modal-header px-0 justify-content-start gap-2">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playAudio}
              ></button>
              <img src="../Images/tools.png" alt="" />
              <h4>Edit Product</h4>
            </div>
            <div className="modal-body p-0 text-center">
              <div className="Edit_Product_Form">
                <input
                  type="hidden"
                  ref={menuIdRef}
                  value={productDetail?.menu_id}
                />
                <SelectField
                  InputName="Product Name"
                  Selectlabel="Product Name"
                  options={productData}
                  onChange={handleProductChange}
                  ref={productRef}
                  db_name="name"
                  productDetail={productDetail ?? { name: "" }}
                />

                <SelectField
                  InputName="Term"
                  Selectlabel="Term"
                  options={productTermsData}
                  ref={termRef}
                  onChange={handleTermChanges}
                  db_name="months"
                  productDetail={productDetail ?? { months: "" }}
                />
                {productDetail?.miles == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="Mileage"
                    Selectlabel="Mileage"
                    options={productMilesData}
                    ref={mileRef}
                    onChange={handleMileChanges}
                    db_name="miles"
                    productDetail={productDetail ?? { miles: "" }}
                  />
                )}

                {productDetail?.service_interval == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="service_interval"
                    Selectlabel="Service Intervals"
                    options={serviceIntervalData}
                    ref={serviceIntervalRef}
                    onChange={handleServiceIntervalChange}
                    db_name="service_interval"
                    productDetail={productDetail ?? { service_interval: "" }}
                  />
                )}

                {productDetail?.deductible == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="Deductible"
                    Selectlabel="Deductible"
                    options={productDeductiblesData}
                    ref={deductibleRef}
                    onChange={handleOtherSelectChanges}
                    db_name="deductible"
                    productDetail={productDetail ?? { deductible: "" }}
                  />
                )}

                {productDetail?.tire_rotations == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="tire_rotations"
                    Selectlabel="Tire Rotations"
                    options={tireRotationData}
                    ref={tireRotationRef}
                    onChange={handleOtherSelectChanges}
                    db_name="tire_rotations"
                    productDetail={productDetail ?? { tire_rotations: "" }}
                  />
                )}
              </div>
              <div className="Total_Price">
                <h4>Total Price</h4>
                <h2>${productPrice}</h2>
              </div>
            </div>
            <div className="modal-footer justify-content-center pb-0">
              <MyButton
                className="w-100 MyBtn"
                title="Save & Change"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleProductSubmit}
              ></MyButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardModals;
