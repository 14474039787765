// useSocket.js
import { useRef, useEffect } from 'react';
import { io } from 'socket.io-client';

const useSocket = (url) => {
  const socketRef = useRef(null);

  if (!socketRef.current) {
    socketRef.current = io(url); 
  }

  const socket = socketRef.current;

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return socket;
};

export default useSocket;
